import { ACCESS_TOKEN, REFRESH_TOKEN } from 'consts';
import Cookies from 'js-cookie';

export const getSessionToken = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const getSession = window.sessionStorage.getItem('time');

  if (!getSession) {
    return null;
  }

  try {
    return JSON.parse(getSession);
  } catch (error) {
    console.error('Ошибка при разборе данных из sessionStorage', error);
    return null;
  }
};

export const removeSessionToken = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem('time');
  }
};

export const getToken = () => ({
  accessToken: Cookies.get(ACCESS_TOKEN),
  refreshToken: Cookies.get(REFRESH_TOKEN),
});

export const setToken = (access_token: string, refresh_token: string) => {
  if (access_token && refresh_token) {
    Cookies.set(ACCESS_TOKEN, access_token, { expires: 1 / 96 });
    Cookies.set(REFRESH_TOKEN, refresh_token, { expires: 7 });
  }
};

export const removeToken = () => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
  removeSessionToken();
};
