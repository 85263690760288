import PinField from 'react-pin-field';
import styles from './PinCodeEmail.module.scss';
import clsx from 'clsx';

type Props = {
  onChange: (code: string) => void;
  code?: string;
  error?: boolean;
  disabled?: boolean;

};

export const PinCodeEmail: React.FC<Props> = ({ onChange, error = false, disabled }) => {
  return (
    <div className={clsx(styles.Container)}>
      <PinField
        className={clsx(styles.PinField, { [styles.error]: error })}
        length={4}
        onChange={onChange}
        validate={(char) => char ? /^[0-9]$/.test(char) : false}
        inputMode="numeric"
        disabled={disabled}
        autoFocus={true}
      />
    </div>
  );
};
