import { Switch } from 'components';
import { Text } from '@mantine/core';
import { useAppSelector } from 'lib/useAppSelect';
import { useUpdatePromotionalOffersNotificationsQuery } from 'lib/profile/useChangeProfileQuery';
import styles from '../../Settings.module.scss';
import { MRC_EMAIL } from 'consts';
import toast from 'react-hot-toast';

export const Promotions: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);
    const { mutate: edit, isLoading } = useUpdatePromotionalOffersNotificationsQuery()
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);
    const onChange = (event: { currentTarget: { checked: boolean; }; }) => {
        if (isLoading) return;
        if(isEmailMirracoin) {
            toast.error("Необходимо привязать почту")
            return;
        }
        edit({ status: event.currentTarget.checked })
    }
    
    return (
        <div className={styles.settings}>
            <div>
                <Text size='lg'>Промоакции</Text>
                <h5>Скидки и бонусы</h5>
            </div>
            <Switch
                onChange={onChange}
                isLoading={isLoading}
                checked={user?.user_info?.notification_settings?.promotional_offers}
            />
        </div>
    )
}
