import { Modal } from "components";
import { VerificationEmail } from "../VerificationEmail";
import { useEffect, useState } from "react";
import { UpdateUserEmail } from "../UpdateUserEmail";
import { useAppSelector } from "lib/useAppSelect";
import { MRC_EMAIL } from "consts";

type Props = {
    opened: boolean;
    close: () => void;
};

export const ModalEmail: React.FC<Props> = ({ opened, close }) => {

    const { user } = useAppSelector((state) => state.user);
    const isCheckVerificationEmail = user && user?.user_info?.verification_email;
    const [verification, setVerification] = useState(true);
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);

    useEffect(() => {
        if (isCheckVerificationEmail || isEmailMirracoin) {
            setVerification(false)
            return;
        } else {
            setVerification(true)
        }
    }, [opened])

    const onClose = () => {
        if (isCheckVerificationEmail || isEmailMirracoin) {
            setVerification(false)
            close();
            return;
        } else {
            setVerification(true)
            close();
        }
    }

    return (
        <Modal opened={opened} close={onClose}>
            {
                verification ?
                    <VerificationEmail onChange={() => setVerification(false)} close={onClose} /> :
                    <UpdateUserEmail onChange={() => setVerification(true)} />
            }
        </Modal>
    )
}