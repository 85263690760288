import { Text } from '@mantine/core'
import { Button } from 'components'
import { secretsEmail } from 'utils';
import { useAppSelector } from 'lib/useAppSelect';
import styles from '../../Settings.module.scss';
import { useDisableAllNotificationsUserQuery } from 'lib/profile/useChangeProfileQuery';
import { MRC_EMAIL } from 'consts';
import toast from 'react-hot-toast';

export const NotificationMail: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);
    const { mutate: off, isLoading } = useDisableAllNotificationsUserQuery()
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);

    const handlerOff = () => {
        if(isEmailMirracoin) {
            toast.error("Необходимо привязать почту")
            return;
        }
        off();
    }
    return (
        <div className={styles.settings}>
            <div>
                <Text size='lg' fw={"bold"}>Уведомления на почте</Text>

                {isEmailMirracoin ?
                    <h5>Для получения уведомлений необходимо добавить почту</h5>
                    :
                    <h5>Будем отправлять письма на {secretsEmail(user?.user_info?.email)}</h5>
                }
            </div>
            <div>
                <Button type='button' onClick={handlerOff} disabled={isLoading}>Отключить все</Button>
            </div>
        </div>
    )
}