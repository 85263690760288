import { Modal as MantineModal } from "@mantine/core";
import styles from './Modal.module.scss';
import { Block } from "components/Block";

type Props = {
    opened: boolean;
    close: () => void;
    children: React.ReactNode;
    title?: string;
}

export const Modal: React.FC<Props> = ({ title, opened, children, close }) => {
    return (
        <MantineModal
            opened={opened}
            closeOnEscape={false}
            closeOnClickOutside={false}
            onClose={close}
            title={<div className={styles.title}>{title}</div>}
            size={styles.size}
            classNames={{
                body: styles.modalBody,
                header: styles.modalHeader,
                content: styles.modalContent,
                close: styles.modalCloseButton,
            }}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            {children}
        </MantineModal>
    )
}