
import { Block } from 'components';
import styles from './Settings.module.scss';
import { FinancialTransaction, News, NotificationMail, NotificationTelegram, Promotions, ReferralProgram } from './components';
import { useAppSelector } from 'lib/useAppSelect';
import { MRC_EMAIL } from 'consts';

export const Settings: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);

    return (
        <Block color='dark'>
            <div className={styles.wrapper}>
                <NotificationMail />
                <ReferralProgram />
                <Promotions />
                <FinancialTransaction />
                <NotificationTelegram />
                <News />
            </div>
        </Block>
    )
}