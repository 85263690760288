import { Text } from '@mantine/core';
import { Switch } from 'components';
import { useAppSelector } from 'lib/useAppSelect';
import styles from '../../Settings.module.scss';
import { useUpdateReferralProgramNotificationsQuery } from 'lib/profile/useChangeProfileQuery';
import { MRC_EMAIL } from 'consts';
import toast from 'react-hot-toast';

export const ReferralProgram: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);
    const { mutate: edit, isLoading } = useUpdateReferralProgramNotificationsQuery()
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);
    const onChange = (event: { currentTarget: { checked: boolean; }; }) => {
        if (isLoading) return;
        if(isEmailMirracoin) {
            toast.error("Необходимо привязать почту")
            return;
        }
        edit({ status: event.currentTarget.checked })
    }

    return (
        <div className={styles.settings}>
            <div>
                <Text size='lg'>Реферальная программа</Text>
                <h5>Вознаграждения</h5>
            </div>
            <Switch
                onChange={onChange}
                isLoading={isLoading}
                checked={user?.user_info?.notification_settings?.referral_program}
            />
        </div>
    )
}
