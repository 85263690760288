import { Button as MantineButton } from '@mantine/core';
import styles from './Button.module.scss';
import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type Props = {
    onClick?: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    color?: "primal" | "black" | "without" | "gradient" | "grey"
    fullWidth?: boolean;
    isLoading?: boolean
    className?: string
}

export const Button: React.FC<Props> = ({ onClick, children, type = "button", disabled, color = "primal", fullWidth, isLoading=false, className }) => {
    return (
        <MantineButton
            classNames={{ root: clsx(styles.button, { [styles.fullWidth]: fullWidth }, styles[color], className), }}
            disabled={disabled || isLoading}
            onClick={type === "button" && onClick ? onClick : undefined}
            type={type}
        >
            {isLoading ? (
                <AiOutlineLoading3Quarters className={styles.loadingIcon} />
            ) : (
                children
            )}
        </MantineButton>
    )
}
