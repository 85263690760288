import HttpService from './http.service';

export type UserT = {
  message: string;
  user_info?: {
    code_2FA: string | null;
    code_2FA_enable: boolean;
    email: string;
    name: string | null;
    patronymic: string | null;
    status: boolean;
    surname: string | null;
    telegram_id: unknown | null;
    verification: boolean;
    fast_registration: boolean | string;
    recent_auth_devices: {
      _id: string;
      type: string;
      timestamp: number;
      message: string;
      user_id: string;
      device_info: string;
      client_ip: string;
    };
    verification_email: boolean;
    wallet_address_mirracoin: string;
    wallet_public_key_mirracoin: string;
    notification_settings: {
      financial_transactions: boolean;
      news: boolean;
      promotional_offers: boolean;
      referral_program: boolean;
      telegram_notifications: boolean;
    };
  };
};

const UserServices = {
  userInfo: async () => {
    const { data }: { data: UserT } = await HttpService().get('get-user-info');
    return data;
  },
};

export default UserServices;
