import { Link } from 'react-router-dom';
import styles from './BlockHeader.module.scss';
import { useAppSelector } from 'lib/useAppSelect';
import { RouteNames } from 'navigate/routeNames';
import { Text } from '@mantine/core'
import { Button, LogoNewTechnologySVG, MenuDropdown, Select } from 'components';
import clsx from 'clsx';


export const BlockHeader: React.FC = () => {
    const { isLogin } = useAppSelector((state) => state.authState);

    const linkTo = isLogin ? RouteNames.PROFILE : RouteNames.LOGIN;
    const linkText = isLogin ? 'Профиль' : 'Войти';
    const linkToRegistration = RouteNames.REGISTRATION;


    const listMirracoin = [
        {
            value: "2",
            label: "Кошелек"
        },
        {
            value: "3",
            label: "Майнинг"
        },
        {
            value: "4",
            label: "Купить Mirracoin"
        },
        {
            value: "5",
            label: "Обозреватель"
        },
        {
            value: "6",
            label: <>Мои <span className={styles.colorItem}>NFT</span></>
        },
        {
            value: "7",
            label: <>Проекты <span className={styles.colorItem}>NFT</span></>
        },
        {
            value: "8",
            label: "White papper"
        },
        {
            value: "9",
            label: "О нас"
        },
    ]

    const listEducation = [
        {
            value: "1",
            label: "Образование"
        },
        {
            value: "2",
            label: "Купить курс"
        },
        {
            value: "3",
            label: "Активные курсы"
        },
    ]

    const listMiningHotel = [
        {
            value: "1",
            label: "Майнинг отель"
        },
        {
            value: "2",
            label: "Мое оборудование"
        },
    ]

    return (
        <div className={styles.header}>
            <div className={styles.titleHeader}>
                <LogoNewTechnologySVG />
                <MenuDropdown title={"Mirracoin"} list={
                    listMirracoin.map((item, i) => (
                        <div key={i} className={styles.list} >
                            <Link to={linkToRegistration}>
                                <Text size={"md"} >
                                    {item.label}
                                </Text>
                            </Link>
                        </div>
                    ))
                }
                />
                <div>
                    <Link to={linkToRegistration}>
                        <Text size={"md"} fw={500}>Майнинг отель</Text>
                    </Link>
                </div>
                <div>
                    <Link to={linkToRegistration}>
                        <Text size={"md"} fw={500}>Образование</Text>
                    </Link>
                </div>
                <div className={styles.nextConference}>
                    <Text size={"md"} fw={500}>Mirra conference</Text>
                </div>
            </div>

            {/* <div>
                RU
            </div> */}
            <div className={styles.enter}>
                {
                    !isLogin &&
                    <Link to={linkToRegistration}>
                        <Button color={'primal'} fullWidth className={styles.button}>
                            Создать аккаунт
                        </Button>
                    </Link>
                }
                <Link to={linkTo}>
                    <Button color={'grey'} fullWidth className={styles.button}>
                        {linkText}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
