import { Text } from '@mantine/core';
import { Switch } from 'components';
import styles from '../../Settings.module.scss';
import { useAppSelector } from 'lib/useAppSelect';
import { useUpdateNewsNotificationsQuery } from 'lib/profile/useChangeProfileQuery';
import toast from 'react-hot-toast';
import { MRC_EMAIL } from 'consts';

export const News: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);
    const { mutate: edit, isLoading } = useUpdateNewsNotificationsQuery()
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);
    const onChange = (event: { currentTarget: { checked: boolean; }; }) => {
        if (isLoading) return;
        if(isEmailMirracoin) {
            toast.error("Необходимо привязать почту")
            return;
        }
        edit({ status: event.currentTarget.checked });
    }

    return (
        <div className={styles.settings}>
            <div>
                <Text size='lg'>Новости</Text>
                <h5>Новые функции и обновления</h5>
            </div>
            <Switch
                onChange={onChange}
                isLoading={isLoading}
                checked={user?.user_info?.notification_settings?.news}
            />
        </div>
    )
}
