import {
  StartPage,
  RegistrationPage,
  ProfilePage,
  NoMatchPage,
  LoginPage,
  ErrorPage,
  WalletPage,
  MyLoginPage,
  BuyMirrcPage,
  AccountsPage,
  ConferencePage,
  ExplorerPage,
  EducationPage,
  NeuralNetworkPage,
  CryptoBankPage,
  WhitePaperPage,
  PiTwoPiPage,
  SecurityPage,
  SettingsPage,
  SupportRestorePage,
  PasswordRecoveryPage,
  WalletHistoryPage,
} from 'pages';
import { RouteNames } from './routeNames';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { ProfileLayout } from 'layouts/ProfileLayout';
import { useAppSelector } from 'lib/useAppSelect';
import { PrimaryLayout } from 'layouts';

type PrivateRouteProps = {
  children: ReactElement;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);
  const location = useLocation();
  const authPath = [RouteNames.LOGIN, RouteNames.REGISTRATION];
  const isExcludePath = authPath.includes(location.pathname as RouteNames);


  if (isExcludePath) {
    return isLogin ? <Navigate to={RouteNames.PROFILE} /> : children
  }

  return isLogin ? children : <Navigate to={RouteNames.LOGIN} />;
};

type PublicRouteProps = {
  children: ReactElement;
};

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);

  if (isLogin && location.pathname !== RouteNames.START) {
    return <Navigate to={RouteNames.PROFILE} />;
  }

  return children
};

export const privateRoutes = [
  {
    path: RouteNames.START,
    element: <StartPage />
  },
  {
    path: RouteNames.PROFILE,
    element: <ProfilePage />
  },
  {
    path: RouteNames.PROFILE + RouteNames.SETTINGS,
    element: <SettingsPage />
  },
  {
    path: RouteNames.PROFILE + RouteNames.SECURITY,
    element: <SecurityPage />
  },
  {
    path: RouteNames.MY_LOGIN,
    element: <MyLoginPage />
  },
  {
    path: RouteNames.WALLET + RouteNames.HISTORY,
    element: <WalletHistoryPage />
  },
  {
    path: RouteNames.WALLET,
    element: <WalletPage />
  },
  {
    path: RouteNames.BUY_MIRRC,
    element: <BuyMirrcPage />
  },
  {
    path: RouteNames.ACCOUNTS,
    element: <AccountsPage />
  },
  {
    path: RouteNames.CONFERENCE,
    element: <ConferencePage />
  },
  {
    path: RouteNames.EXPLORER,
    element: <ExplorerPage />
  },
  {
    path: RouteNames.EDUCATION,
    element: <EducationPage />
  },
  {
    path: RouteNames.NEURAL_NETWORK,
    element: <NeuralNetworkPage />
  },
  {
    path: RouteNames.CRYPTO_BANK,
    element: <CryptoBankPage />
  },
  {
    path: RouteNames.WHITE_PAPER,
    element: <WhitePaperPage />
  },
  {
    path: RouteNames.P2P,
    element: <PiTwoPiPage />
  },
  {
    path: RouteNames.ERROR,
    element: <ErrorPage />
  },
  {
    path: RouteNames.NOMATCH,
    element: <NoMatchPage />
  },
]


export const Routers = () => {
  const routes = useRoutes([
    {
      path: RouteNames.START,
      element: (
        <PublicRoute>
          <StartPage />
        </PublicRoute>
      ),
    },
    {
      path: RouteNames.LOGIN,
      element: (
        <PublicRoute>
          <PrimaryLayout>
            <LoginPage />
          </PrimaryLayout>
        </PublicRoute>
      ),
    },
    {
      path: RouteNames.REGISTRATION,
      element: (
        <PublicRoute>
          <PrimaryLayout>
            <RegistrationPage />
          </PrimaryLayout>
        </PublicRoute>
      ),
    },
    {
      path: RouteNames.SUPPORT_RESTORE,
      element: (
        <PublicRoute>
          <PrimaryLayout>
            <SupportRestorePage />
          </PrimaryLayout>
        </PublicRoute>
      ),
    },
    {
      path: RouteNames.PASSWORD_RECOVERY,
      element: (
        <PublicRoute>
          <PrimaryLayout>
            <PasswordRecoveryPage />
          </PrimaryLayout>
        </PublicRoute>
      ),
    },
    {
      path: RouteNames.START,
      element: (
        <PrivateRoute>
          <ProfileLayout />
        </PrivateRoute>
      ),
      children: privateRoutes,
    },
    {
      path: RouteNames.ERROR,
      element: <ErrorPage />,
    },
    {
      path: RouteNames.NOMATCH,
      element: <NoMatchPage />,
    },
  ]);

  return routes;
};
