import { API_URL } from 'consts';
import { getSessionToken, getToken } from './token.service';
import axios from 'axios';

export const URL = `${API_URL}/api/`;

export const HttpService = () => {
  const cookies = getToken().accessToken;
  const session = getSessionToken();

  const token = cookies || session;

  return axios.create({
    withCredentials: true,
    baseURL: URL,
    headers: {
      accept: 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  });
};

export default HttpService;
