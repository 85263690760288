import { Text } from '@mantine/core'
import { Button, CheckedSVG } from 'components'
import { useDisclosure } from '@mantine/hooks'
import { useAppSelector } from 'lib/useAppSelect'
import { ModalEmail } from './components';
import { MRC_EMAIL } from 'consts';

import styles from '../../Security.module.scss';
import { secretsEmail } from 'utils';

export const EmailCheck: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);

    const [email, { open, close }] = useDisclosure(false);
    const isCheckNotVerificationEmail = user && !user?.user_info?.verification_email;

    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);

    return (
        <>
            <ModalEmail
                close={close}
                opened={email}
            />
            <div className={styles.settings}>
                <div className={styles.header}>
                    <Text size='lg'>Почта</Text>
                    <h5>Для авторизации, смены пароля и системных писем</h5>
                </div>
                <div className={styles.email}>
                    {!isCheckNotVerificationEmail && <CheckedSVG />}
                    <h5>{secretsEmail(user?.user_info?.email)}</h5>
                </div>
                {
                    <Button
                        type='button'
                        className={styles.button}
                        onClick={open}
                    >
                        {
                            isCheckNotVerificationEmail && !isEmailMirracoin ?
                                "Подтвердить" :
                                "Изменить"
                        }
                    </Button>
                }
            </div>
        </>
    )
}