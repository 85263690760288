import styles from './CardSections.module.scss';
import block_zebra from 'assets/images/block_zebra.png';
import block_p2p from 'assets/images/block_p2p.png';
import block_hand from 'assets/images/block_hand.png';
import block_data from 'assets/images/block_data.png';
import block_coin from 'assets/images/block_coin.png';
import block_boxes from 'assets/images/block_boxes.png';
import { Title } from '@mantine/core';

export const CardSections: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.card}>
                    <img src={block_boxes} alt='boxes' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Blockchain
                                <br />
                                Mirracoin
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Время генерации блоков ~3 секунды, обработка транзакций моментально</h5>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={block_hand} alt='hand' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Образовательные курсы
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Получить знания по цифровой валюте</h5>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={block_data} alt='data' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Mirra
                                <br />
                                дата центр
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Возможность для начинающих
                            и опытных майнеров</h5>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={block_zebra} alt='zebra' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Mirra
                                <br />
                                IT-разработки
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Решения в области web-разработки, робототехники и умных устройств</h5>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={block_coin} alt='coin' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Mirra
                                <br />
                                Crypto-Bank
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Современные технологии для обеспечения безопасности данных</h5>
                        </div>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={block_p2p} alt='p2p' />
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <Title size={35}>
                                Mirra P2P
                            </Title>
                        </div>
                        <div className={styles.body}>
                            <h5>Возможность для начинающих
                            и опытных майнеров</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}