import { ArrowLineSVG, Block, ExchangeSVG } from 'components';
import { Text, Title } from '@mantine/core';
import styles from './FinancialTransactions.module.scss';

export const FinancialTransactions: React.FC = () => {
    return (
        <Block color="dark" className={styles.blockWallet}>
            <div className={styles.rowMany}>
                <div>
                    <div className={styles.head}>
                        <Text size='xs' fw={450}>Всего</Text>
                    </div>
                    <div className={styles.count}>
                        <Title size={20} fw={700}>$0.00</Title>
                    </div>
                </div>
                <div>
                    <div className={styles.head}>
                        <Text size='xs' fw={450}>Кошельки</Text>
                    </div>
                    <div className={styles.count}>
                        <Title size={13} fw={700}>$0.00</Title>
                    </div>
                </div>
                <div>
                    <div className={styles.head}>
                        <Text size='xs' fw={450}>Майнинг</Text>
                    </div>
                    <div className={styles.count}>
                        <Title size={13} fw={700}>$0.00</Title>
                    </div>
                </div>
                <div>
                    <div className={styles.head}>
                        <Text size='xs' fw={450}>P2P</Text>
                    </div>
                    <div className={styles.count}>
                        <Title size={13} fw={700}>$0.00</Title>
                    </div>
                </div>
            </div>
            <div className={styles.rowDetail}>
                <div>
                    <ExchangeSVG />
                    <Title size={13} fw={700}>P2P-обмены</Title>
                </div>
                <div>
                    <ExchangeSVG />
                    <Title size={13} fw={700}>Пополнить</Title>
                </div>
                <div>
                    <ArrowLineSVG direction='top' />
                    <Title size={13} fw={700}>Отправить</Title>
                </div>
                <div>
                    <ArrowLineSVG direction='down' />
                    <Title size={13} fw={700}>Вывести на карту</Title>
                </div>
                <div>
                    <ArrowLineSVG direction='down' />
                    <Title size={13} fw={700}>Импорт кошелька</Title>
                </div>
            </div>
        </Block>
    )
}