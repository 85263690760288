type Props = {
    width?: number
    color?: string
}

export const EmailSVG: React.FC<Props> = ({ width = 12, color="#222222" }) => {
    return (
        <svg width={width} height={width/1.8} viewBox="0 0 42 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.890438 2.66138C0.872944 2.76271 0.864197 2.86845 0.864197 2.96979V26.7877C0.864197 26.8935 0.872944 26.9948 0.890438 27.0961L13.8757 14.8788L0.890438 2.66138Z" fill={color} />
            <path d="M39.6232 0.863826C39.5226 0.850609 39.4176 0.841797 39.3127 0.841797H3.11659C3.01162 0.841797 2.91103 0.850609 2.80606 0.863826L21.2146 18.1788L39.6232 0.863826Z" fill={color} />
            <path d="M22.1681 20.8838C21.6432 21.3773 20.7904 21.3773 20.2612 20.8838L15.787 16.6763L2.80606 28.8892C2.90665 28.9025 3.01162 28.9113 3.11659 28.9113H39.3127C39.4176 28.9113 39.5182 28.9025 39.6232 28.8892L26.6379 16.6763L22.1637 20.8838H22.1681Z" fill={color} />
            <path d="M41.5388 2.66138L28.5536 14.8788L41.5388 27.0961C41.5563 26.9948 41.5651 26.8891 41.5651 26.7877V2.96979C41.5651 2.86405 41.5563 2.76271 41.5388 2.66138Z" fill={color} />
        </svg>

    )
}
