import { Text } from '@mantine/core';
import styles from '../../Settings.module.scss';
import { Button } from 'components';
import { useAppSelector } from 'lib/useAppSelect';
import { useDisclosure } from '@mantine/hooks';
import { AddedTelegram } from './components';

export const NotificationTelegram: React.FC = () => {
    const [opened, { open, close }] = useDisclosure(false);

    const { user } = useAppSelector((state) => state.user);

    const telegramId = user?.user_info?.telegram_id;
    
    return (
        <>
            {opened && <AddedTelegram close={close} opened={opened} />}
            <div className={styles.settings}>
                <div>
                    <Text size='lg'>Уведомления в Telegram</Text>
                    <h5>Будем отправлять уведомления Telegram бота @mirrc_bot </h5>
                </div>
                <Button type='button' onClick={open}>{telegramId ? "Изменить Telegram" : "Привязать Telegram"}</Button>
            </div>
        </>
    )
}