import { Block, PostSVG } from "components";
import { useCheckVerificationEmailQuery, useVerificationEmailQuery } from "lib/auth/useAuthorizationQuery";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "lib/useAppSelect";
import { Text } from "@mantine/core";
import { formatTime } from "utils";
import toast from "react-hot-toast";
import { PinCodeEmail } from "./PinCodeEmail";

import styles from './VerificationEmail.module.scss';

type Props = {
    onChange: () => void;
    close: () => void;
}

export const VerificationEmail: React.FC<Props> = ({ onChange, close }) => {
    const [timeLeft, setTimeLeft] = useState(120);
    const [code, setCode] = useState("");
    const { mutate: checkCode, isError, isSuccess, isLoading, reset } = useCheckVerificationEmailQuery()

    const { refetch, isLoading: isLoadingAgain } = useVerificationEmailQuery();
    const { user } = useAppSelector((state) => state.user);

    useEffect(() => {
        if (isSuccess) return;
        refetch().catch((error) =>
            console.error("Ошибка при отправке кода:", error)
        );
        setTimeLeft(120);

    }, [refetch]);

    useEffect(() => {
        if (isSuccess) return;
        let timer: NodeJS.Timeout | undefined;
        if (timeLeft > 0) {
            timer = setTimeout(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [timeLeft]);

    const sendCodeAgain = async () => {
        if (isLoadingAgain) return;
        try {
            await refetch();
            setTimeLeft(120);
        } catch (error) {
            console.error("Ошибка при повторной отправке кода:", error);
            toast.error("Ошибка при повторной отправке кода");
        }
    };

    useEffect(() => {
        if (code.length === 4) {
            checkCode({ code })
        }

        if (isSuccess) {
            setCode("");
            handlerClose();
            toast.success('Почта успешно подтверждена');
        }
    }, [isSuccess, code]);

    const sendCode = (value: string) => {
        setCode(value)
    }

    const errorCode = useMemo(() => {
        if (code.length === 3) {
            reset();
        }
        return isError && code.length === 4 || false
    }, [isError, code]);


    const handlerClose = () => {
        close();
        reset();
    }

    return (
        <Block>
            <div className={styles.wrapper}>
                <PostSVG />
                <div className={styles.header}>
                    <h4>Введите код из письма <br /> для подтверждения</h4>
                </div>
                <div className={styles.email}>
                    <h5>Письмо отправлено на почту <br />
                        {user?.user_info?.email}
                    </h5>
                </div>
                <div className={styles.pin}>
                    <PinCodeEmail
                        onChange={sendCode}
                        error={errorCode}
                        disabled={isLoading}
                        code={code}
                    />
                    {
                        errorCode && <div className={styles.error}>
                            <h5>Неверный код</h5>
                        </div>
                    }
                </div>
                <div className={styles.againAndTime}>
                    {timeLeft === 0 ? (
                        <div className={styles.sendCodeAgain} onClick={sendCodeAgain}>
                            <Text size="md">Отправить код ещё раз</Text>
                        </div>
                    ) : (
                        <div className={styles.time}>
                            <h5>Письмо с кодом придет в течение {formatTime(timeLeft)}</h5>
                        </div>
                    )}
                </div>
                <div className={styles.changeEmail} onClick={onChange} >
                    <Text size="md">Изменить почту</Text>
                </div>
            </div>
        </Block>
    );
};
