import { Text } from '@mantine/core'
import { Menu } from '@mantine/core';
import { ArrowSVG } from 'components/SVG';
import { useState } from 'react';
import styles from './MenuDropdown.module.scss';

type Props = {
    title: string;
    list: React.ReactNode;
    disabled?: boolean;
}
export const MenuDropdown: React.FC<Props> = ({ title, list, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Menu
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            classNames={{
                dropdown: styles.dropdown,
                item: styles.item,
            }}
        >
            <Menu.Target>
                <div className={styles.title}>
                    <div>
                        <Text size={"md"} fw={500}>{title}</Text>
                    </div>
                    <div className={styles.arrow}>
                        <ArrowSVG width={7} direction={isOpen ? "down" : "top"} />
                    </div>
                </div>
            </Menu.Target>
            <Menu.Dropdown>{list}</Menu.Dropdown>
        </Menu>
    )
}
