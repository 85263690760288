type Props = {
    direction?: 'left' | 'right' | 'top' | 'down';
    width?: number;
}

export const ArrowLineSVG: React.FC<Props> = ({ direction = 'right', width = 12 }) => {
    const rotation = {
        left: '270deg',
        right: '90deg',
        down: '180deg',
        top: '0deg',
    }[direction];

    return (
        <svg style={{ transform: `rotate(${rotation})` }} width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.453243 5.60577L5.63846 0.246646C5.95665 -0.082216 6.47254 -0.082216 6.79074 0.246646L11.976 5.60577C12.2941 5.93463 12.2941 6.46783 11.976 6.79669C11.6578 7.12555 11.1419 7.12555 10.8237 6.79669L7.02938 2.87513L7.02938 16L5.39982 16L5.39982 2.87513L1.60551 6.79669C1.28732 7.12555 0.771434 7.12555 0.453243 6.79669C0.135052 6.46783 0.135052 5.93463 0.453243 5.60577Z" fill="#8C64D8" />
        </svg>
    );
};
