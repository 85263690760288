import { BigCheckedSVG, Button, InputPassword } from 'components';
import { RiLockPasswordFill } from 'react-icons/ri';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RouteNames } from 'navigate/routeNames';
import { useNavigate } from 'react-router-dom';
import styles from './FormNewPassword.module.scss'
import { useSendPasswordForPasswordRecoveryQuery } from 'lib';
import { Title } from '@mantine/core';


type Props = {
    email?: string | null;
    token?: string | null;
}

type NewPasswordT = {
    password: string;
    re_password: string;
}

export const FormNewPassword: React.FC<Props> = ({ email, token }) => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm<NewPasswordT>();

    const navigate = useNavigate();

    const back = () => {
        navigate(RouteNames.LOGIN);
    }

    const {
        mutate: sendPassword,
        isSuccess,
        isLoading,
    } = useSendPasswordForPasswordRecoveryQuery()


    const onSubmit: SubmitHandler<NewPasswordT> = ({ password }) => {
        if (!email || !token) return
        sendPassword({ email, token, password });
    };

    return (
        <div className={styles.content}>
            {isSuccess && (
                <>
                    <div className={styles.headerSuccess}>
                        <Title size={40}>Доступ к аккаунту</Title>
                        <Title size={40}>восстановлен</Title>
                    </div>
                    <div className={styles.checked}>
                        <BigCheckedSVG />
                    </div>
                    <div className={styles.wrapper}>
                        <Button type="button" onClick={back} fullWidth >Перейти к авторизации</Button>
                    </div>
                </>
            )}
            {!isSuccess && (
                <>
                    <div className={styles.header}>
                        <Title size={40}>Введите новый</Title>
                        <Title size={40}>пароль</Title>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Заполните поле",
                                minLength: {
                                    value: 8,
                                    message: "Пароль должен быть длиной не менее 8 символов"
                                }
                            }}
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    label='Новый пароль'
                                    required
                                    placeholder="Пароль"
                                    {...field}
                                    error={!!errors?.password}
                                    errorMessage={errors?.password?.message}
                                />
                            )}
                        />
                        <Controller
                            name="re_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Заполните поле",
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const passwordValue = watch("password");
                                        return value === passwordValue || "Пароли не совпадают";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    label='Повторите пароль'
                                    required
                                    placeholder="Пароль"
                                    {...field}
                                    error={!!errors?.re_password}
                                    errorMessage={errors?.re_password?.message}
                                />
                            )}
                        />
                        <div className={styles.buttons}>
                            <Button type="submit" fullWidth isLoading={isLoading}>Продолжить</Button>
                            <Button type="button" color='grey' fullWidth onClick={back}>Отмена</Button>
                        </div>
                    </form>
                </>
            )}
        </div>
    )
}

