import { Title, Text } from '@mantine/core'
import styles from './PreviewBlock.module.scss'
import { LogoSVG } from 'components/SVG'
import { NavLink } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';

export const PreviewBlock: React.FC = () => {
    return (
        <>
            <div className={styles.backgroundContainer}>
                    <NavLink to={RouteNames.START} className={styles.logo}>
                        <LogoSVG />
                    </NavLink>
                <div className={styles.fullWidthImage}></div>
                <div className={styles.gradient}></div>
                <div className={styles.centeredImage}>
                    <div className={styles.wallet}>
                        <img src="images/wallet.png" alt="Centered" />
                    </div>
                    <div className={styles.title}>
                        <Title size={30}>
                            <span className={styles.gradientText}>
                                Надёжный кошелёк&nbsp;
                            </span>
                            с функциями обмена и вывода криптовалют
                        </Title>
                    </div>
                    <div className={styles.options}>
                        <Text size={"lg"}>
                            Сохраняйте криптовалюту, обменивайте её, получайте прибыль и выводите средства быстро и выгодно
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}
