import { Title } from '@mantine/core';
import styles from './SupportRestore.module.scss';
import { FormSupportRestore } from './components';

export const SupportRestore: React.FC = () => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Title size={40}>Восстановление</Title>
                    <Title size={40}>пароля</Title>
                </div>
                <FormSupportRestore />
            </div>
        </div>
    )
}
