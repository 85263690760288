import { useMutation, useQuery } from 'react-query';
import { LoginQueryT, SignupQueryT } from 'types/auth';
import { useAppDispatch } from 'lib/useAppDispatch';
import authService, { CheckVerificationEmailT, NewEmailT } from 'services/auth.service';
import { setToken } from 'services';
import { toggleAuthState } from 'store/authStateSlice';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';
import toast from 'react-hot-toast';
import { queryClientBase } from 'index';
import { setFastRegistrationDate } from 'store/fastEnterStateSlice';

export const useLoginQuery = (rememberMe: boolean) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    async (data: LoginQueryT) => await authService.login(data),
    {
      retry: false,
      onSuccess: async (data) => {
        if (
          data.data.jwt_tokens.access_token &&
          data.data.jwt_tokens.refresh_token
        ) {
          if (rememberMe) {
            setToken(
              data.data.jwt_tokens.access_token,
              data.data.jwt_tokens.refresh_token
            );
          } else {
            sessionStorage.setItem(
              'time',
              JSON.stringify(data.data.jwt_tokens.access_token)
            );
          }
        }
        dispatch(toggleAuthState({ isLogin: true }));
        navigate(RouteNames.PROFILE);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
        dispatch(toggleAuthState({ isLogin: false }));
      },
    }
  );
};

export const useVerificationEmailQuery = () => {
  return useQuery(
    ['verificationEmail'],
    async () => await authService.verificationEmail(),
    {
      enabled: false,
      retry: 0,
    }
  );
};

export const useCheckVerificationEmailQuery = () => {
  return useMutation(
    async (data: CheckVerificationEmailT) =>
      await authService.checkVerificationEmail(data),
    {
      retry: false,
      onSuccess: async (data) => {
        queryClientBase.refetchQueries(['userInfo']);
      },
    }
  );
};

export const useUpdateUserEmailQuery = () => {
  return useMutation(
    async (data: NewEmailT) => await authService.updateUserEmail(data),
    {
      retry: false,
      onSuccess: async (data) => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: async (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useFastRegistrationQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(async () => await authService.fastRegistration(), {
    retry: false,
    onSuccess: async (data) => {
      setToken(data.jwt_tokens.access_token, data.jwt_tokens.refresh_token);
      dispatch(toggleAuthState({ isLogin: true }));
      dispatch(setFastRegistrationDate({userFastDate: data}))
      navigate(RouteNames.PROFILE);
    },
    onError: async (error: any) => {
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred.';
      toast.error(errorMessage);
      dispatch(toggleAuthState({ isLogin: false }));
      navigate(RouteNames.LOGIN);
    },
  });
};

export const useNewRegistrationQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    async (data: SignupQueryT) => await authService.newRegistration(data),
    {
      retry: false,
      onSuccess: async (data) => {
        setToken(data.jwt_tokens.access_token, data.jwt_tokens.refresh_token);
        dispatch(toggleAuthState({ isLogin: true }));
        navigate(RouteNames.PROFILE);
      },
      onError: async (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.LOGIN);
      },
    }
  );
};
