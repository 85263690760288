type Props = {
    width?: number;
}

export const CheckedSVG: React.FC<Props> = ({ width = 20 }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#669D1B" />
            <path d="M7.88884 14.4626C7.61884 14.4526 7.35884 14.3326 7.17884 14.1226L4.22884 10.8326C3.85884 10.4226 3.89884 9.78256 4.31884 9.42256C4.73884 9.06256 5.36884 9.09256 5.72884 9.51256L7.94884 12.0226L14.2788 5.86256C14.6488 5.45256 15.2788 5.41256 15.6888 5.77256C16.0988 6.14256 16.1388 6.77256 15.7788 7.18256C15.7488 7.22256 15.7088 7.25256 15.6688 7.29256L8.58884 14.2126C8.39884 14.3826 8.14884 14.4826 7.88884 14.4726V14.4626Z" fill="#33333A" />
        </svg>
    )
}
