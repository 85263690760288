import { Button } from 'components'
import { Text } from '@mantine/core'

import styles from '../../Security.module.scss';
import { useDisclosure } from '@mantine/hooks';
import { NewPassword } from './components';

export const PasswordChange: React.FC = () => {

    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <NewPassword opened={opened} close={close}/>
            <div className={styles.settings}>
                <div className={styles.header}>
                    <Text size='lg'>Пароль</Text>
                    <h5>Используется для авторизации</h5>
                </div>
                <div></div>
                <Button type='button' className={styles.button} onClick={open}>Изменить</Button>
            </div>
        </>
    )
}