
import { FinancialTransactions, TableTransactions } from './components';

export const Wallet: React.FC = () => {
    return (
        <>
            <FinancialTransactions />
            <TableTransactions />
        </>
    );
};
