import { Block, Button, InputPassword, Modal } from "components";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useChangeNewPasswordQuery } from "lib/profile/useChangeProfileQuery";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { RiLockPasswordFill } from "react-icons/ri";
import { NewPasswordT } from "services/profile.service";
import { useAppSelector } from "lib/useAppSelect";

import styles from './NewPassword.module.scss';

type Props = {
    opened: boolean;
    close: () => void;
}

export const NewPassword: React.FC<Props> = ({ opened, close }) => {
    const { control, handleSubmit, watch, clearErrors, reset: resetInputs, formState: { errors } } = useForm<NewPasswordT>();
    const { user } = useAppSelector((state) => state.user);
    const {
        mutate: sendNewPassword,
        isSuccess,
        isLoading,
        reset
    } = useChangeNewPasswordQuery();

    useEffect(() => {
        if (isSuccess) {
            handlerClose();
            toast.success('Пароль успешно обновлен');
        }
    }, [isSuccess]);

    const handlerClose = () => {
        close();
        reset();
        resetInputs();
        clearErrors();
    }

    const onSubmit: SubmitHandler<NewPasswordT> = (data) => {
        if (!data) return;
        if (!data.old_password) {
            data.old_password = ""
        }
        sendNewPassword(data);
    };

    const isFastRegistration = user && user?.user_info?.fast_registration;

    return (
        <Modal opened={opened} close={handlerClose}>
            <Block>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
                    <div className={styles.header}>
                        <h4>Смена пароля</h4>
                    </div>
                    <div className={styles.inputs}>
                        {
                            !isFastRegistration &&
                            <Controller
                                name="old_password"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Заполните поле" }}
                                render={({ field }) => (
                                    <InputPassword
                                        icon={<RiLockPasswordFill />}
                                        fullWidth
                                        label='Старый пароль'
                                        required
                                        placeholder="Пароль"
                                        {...field}
                                        error={!!errors?.old_password}
                                    />
                                )}
                            />
                        }
                        <Controller
                            name="new_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Заполните поле",
                                minLength: {
                                    value: 8,
                                    message: "Пароль должен быть длиной не менее 8 символов"
                                }
                            }}
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    label='Новый пароль'
                                    required
                                    placeholder="Пароль"
                                    {...field}
                                    error={!!errors?.new_password}
                                    errorMessage={errors?.new_password?.message}
                                />
                            )}
                        />
                        <Controller
                            name="re_password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Заполните поле",
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const passwordValue = watch("new_password");
                                        return value === passwordValue || "Пароли не совпадают";
                                    }
                                }
                            }}
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    label='Повторите пароль'
                                    required
                                    placeholder="Пароль"
                                    {...field}
                                    error={!!errors?.re_password}
                                    errorMessage={errors?.re_password?.message}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.submit}>
                        <Button type={"submit"} fullWidth isLoading={isLoading}>Изменить</Button>
                    </div>
                </form>
            </Block>
        </Modal>
    );
};
