export const CryptoBankPage: React.FC = () => <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#555'
}}>
    Раздел на подключении. Скоро будет доступен.
</div>

