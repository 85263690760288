import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import recoveryService, { SendEmailRecoveryPasswordT, SendPasswordRecoveryPasswordT } from 'services/recovery.service';

export const useSendEmailForPasswordRecoveryQuery = () => {
  return useMutation(
    async (data: SendEmailRecoveryPasswordT) => await recoveryService.sendEmail(data),
    {
      retry: false,
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage, {
          id: 'unique-error',
          duration: 3000,
        });
      },
    }
  );
};

export const useSendPasswordForPasswordRecoveryQuery = () => {
    return useMutation(
      async (data: SendPasswordRecoveryPasswordT) => await recoveryService.sendPassword(data),
      {
        retry: false,
        onError: (error: any) => {
          const errorMessage =
            error?.response?.data?.message || 'An unexpected error occurred.';
          toast.error(errorMessage, {
            id: 'unique-error',
            duration: 3000,
          });
        },
      }
    );
  };
  