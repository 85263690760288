import { Footer } from 'components';
import {
    BlockHeader,
    BlockTitle,
    CardSections,
    ReferenceBlock,
    SwiperBlock
} from './components';

import styles from './Start.module.scss';
import { useAppSelector } from 'lib';

export const Start: React.FC = () => {
    const { isLogin } = useAppSelector((state) => state.authState);
    return (
        <div className={styles.page}>
            <div className={styles.sectionTop}>
                <BlockHeader />
                <BlockTitle />
                <div className={styles.gradient}></div>
                <div className={styles.fullWidthImage}></div>
            </div>
            <SwiperBlock />
            <CardSections />
            {!isLogin && <ReferenceBlock />}
            <Footer />
        </div>
    )
}
