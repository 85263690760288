
type Props = {
    direction?: 'vertical' | 'horizont';
    width?: number;
}

export const ExchangeSVG: React.FC<Props> = ({ direction = 'vertical' }) => {

    const rotation = {
        vertical: '0deg',
        horizont: '90deg',
    }[direction];

    return (
        <svg style={{ transform: `rotate(${rotation})` }} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.29541 0.392469C9.63091 0.149579 10.0998 0.224679 10.3427 0.560209L13.4648 4.87317C13.6301 5.10144 13.6534 5.40306 13.5253 5.65403C13.3971 5.905 13.1391 6.06294 12.8573 6.06294H0.75C0.33579 6.06294 0 5.72717 0 5.31295C0 4.89874 0.33579 4.56295 0.75 4.56295H11.3885L9.12771 1.43978C8.88481 1.10425 8.95991 0.635349 9.29541 0.392469ZM0.0820498 10.3459C0.2102 10.0949 0.46821 9.93704 0.75 9.93704H12.8573C13.2715 9.93704 13.6073 10.2727 13.6073 10.687C13.6073 11.1012 13.2715 11.437 12.8573 11.437H2.21877L4.47956 14.5602C4.72244 14.8957 4.64733 15.3646 4.31179 15.6075C3.97626 15.8504 3.50737 15.7753 3.26449 15.4397L0.14247 11.1267C-0.0227701 10.8985 -0.0461002 10.5968 0.0820498 10.3459Z" fill="#8C64D8" />
        </svg>
    )
}