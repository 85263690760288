import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Mousewheel } from 'swiper/modules';
import { useRef } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import card_galaxy from 'assets/images/card_galaxy.png';
import card_painting from 'assets/images/card_painting.png';
import card_girl_ai from 'assets/images/card_girl_ai.png';
import card_call_user from 'assets/images/card_call_user.png';

import styles from './SwiperBlock.module.scss';
import { ArrowSVG, Button } from 'components';
import { useAppSelector } from 'lib';
import { NBSP } from 'consts/consts';

export const SwiperBlock: React.FC = () => {
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const swiperRef = useRef<SwiperType | null>();

    const handleNextSlide = () => swiperRef.current?.slideNext();
    const handleBackSlide = () => swiperRef.current?.slidePrev();

    return (
        <div className={styles.wrapper}>
            {
                !isMobile &&
                <div
                    className={styles.button}
                    onClick={handleBackSlide}
                >
                    <ArrowSVG direction='right' />
                </div>
            }
            <Swiper
                className={styles.swiper}
                onSwiper={(swiper: SwiperType) => {
                    swiperRef.current = swiper
                }}
                spaceBetween={1}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination, Mousewheel]}
                slidesPerView={1}
                allowTouchMove={true}
                freeMode={true}
                watchSlidesProgress={true}
                effect="fade"
                speed={500}
                loop
                grabCursor
            >
                <SwiperSlide className={styles.box}>
                    <div className={styles.card}>
                        <div className={styles.image}>
                            <img src={card_galaxy} alt='galaxy' />
                            <div className={styles.textInImage}>
                                ИСКУССТВО ОБЪЕДИНЯЕТ МИР.
                                <br/>
                                4-я совместная международная выставка{NBSP}ЧЕЛОВЕК
                                <br/>
                                И ПРОСТРАНСТВО
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div></div>
                            <div className={styles.title}>
                                <h4>
                                    Официальная параллельная программа Венецианской биеннале 2024 года
                                </h4>
                            </div>
                            <Button className={styles.buttonPrev}>
                                <h4>Посмотреть</h4>
                            </Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={styles.box}>
                    <div className={styles.card}>
                        <div className={styles.image}>
                            <img src={card_painting} alt='painting' />
                            <div className={styles.customText}>
                                ХУДОЖНИКИ
                                <br />
                                РОССИИ
                            </div>
                        </div>
                        <div className={styles.block}>
                            <Button color={"gradient"} className={styles.buttonNext}>
                                NFT
                            </Button>
                            <div className={styles.title}>
                                <h4>
                                    Галерея и онлайн-магазин оригинальных
                                    {!isMobile && <br />}
                                    картин
                                    {!isMobile && <br />}
                                    и цифровых копий
                                    в формате NFT
                                </h4>
                            </div>
                            <Button className={styles.buttonPrev}>
                                <h4>Посмотреть</h4>
                            </Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={styles.box}>
                    <div className={styles.card}>
                        <div className={styles.image}>
                            <img src={card_girl_ai} alt='girl ai' />
                        </div>
                        <div className={styles.block}>
                            <Button color={"gradient"} className={styles.buttonNext}>
                                Mirra AI
                            </Button>
                            <div className={styles.title}>
                                <h4>
                                    Генерирует ответы на запросы, как человек
                                    в любой предметной области
                                </h4>
                            </div>
                            <Button className={styles.buttonPrev}>
                                <h4>Посмотреть</h4>
                            </Button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={styles.box}>
                    <div className={styles.card}>
                        <div className={styles.image}>
                            <img src={card_call_user} alt='call user' />
                        </div>
                        <div className={styles.block}>
                            <Button color={"gradient"} className={styles.buttonNext}>
                                Mirra conference
                            </Button>
                            <div className={styles.title}>
                                <h4>
                                    Генерирует ответы на запросы, как человек
                                    в любой предметной области
                                </h4>
                            </div>
                            <Button className={styles.buttonPrev}>
                                <h4>Посмотреть</h4>
                            </Button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            {
                !isMobile &&
                <div
                    className={styles.button}
                    onClick={handleNextSlide}
                >
                    <ArrowSVG direction='left' />
                </div>
            }
        </div>
    )
}