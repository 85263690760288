import HttpService from './http.service';

export type NewPasswordT = {
  old_password: string;
  new_password: string;
  re_password: string;
};

export type DeviceT = {
  _id: string;
  type: string;
  timestamp: number;
  message: string;
  user_id: string;
  device_info: string;
  client_ip: string;
};

export type GetRecentAuthDevicesT = {
  status: boolean;
  data: DeviceT[];
};
export type FormTelegram = {
  telegram_id: string;
};

const ProfileService = {
  telegram: async (req: FormTelegram) => {
    const { data }: { data: any } = await HttpService().post(
      `update-telegram-id`,
      req
    );
    return data;
  },

  newPassword: async (req: NewPasswordT) => {
    const { data }: { data: any } = await HttpService().post(
      `update-user-password`,
      req
    );
    return data;
  },
  // Активность
  getRecentAuthDevices: async () => {
    const { data }: { data: GetRecentAuthDevicesT } = await HttpService().post(
      `get-recent-auth-devices`
    );
    return data;
  },

  // Обновление уведомлений в Telegram
  updateTelegramNotifications: async (req: { status: boolean }) => {
    const { data }: { data: any } = await HttpService().post(
      `update-telegram-notifications`,
      req
    );
    return data;
  },

  // Обновление уведомлений о финансовых транзакциях
  updateFinancialTransactionsNotifications: async (req: {
    status: boolean;
  }) => {
    const { data }: { data: any } = await HttpService().post(
      `update-financial-transactions-notifications`,
      req
    );
    return data;
  },

  // Обновление уведомлений о реферальной программе
  updateReferralProgramNotifications: async (req: { status: boolean }) => {
    const { data }: { data: any } = await HttpService().post(
      `update-referral-program-notifications`,
      req
    );
    return data;
  },

  // Обновление новостных уведомлений
  updateNewsNotifications: async (req: { status: boolean }) => {
    const { data }: { data: any } = await HttpService().post(
      `update-news-notifications`,
      req
    );
    return data;
  },

  // Обновление уведомлений о промо-офферах
  updatePromotionalOffersNotifications: async (req: { status: boolean }) => {
    const { data }: { data: any } = await HttpService().post(
      `update-promotional-offers-notifications`,
      req
    );
    return data;
  },

  // Отключение всех уведомлений для пользователя
  disableAllNotificationsUser: async () => {
    const { data }: { data: any } = await HttpService().post(
      `disable-all-notifications-user`
    );
    return data;
  },
};

export default ProfileService;
