import { EmailSVG, LogoNewTechnologySVG, TelegramSVG } from 'components/SVG';
import styles from './Footer.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { TG_BOT_URL } from 'lib/consts/links';

export const Footer: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.blockLogo}>
                        <div className={styles.cardLogo}><LogoNewTechnologySVG /></div>
                        <div className={styles.inRussia}>
                            <h5>Сделано в России 🇷🇺</h5>
                        </div>
                        <div className={styles.contacts}>

                            <Link
                                to={TG_BOT_URL}
                                type="empty"
                                target="_blank"
                                className={styles.circleBlock}
                            >
                                <TelegramSVG width={38} color='black' />
                            </Link>

                            <div className={styles.circleBlock}>
                                <EmailSVG width={50} color='black' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.block}>
                        <div className={styles.section}>
                            <div className={styles.card}>
                                <div className={styles.head}>
                                    MIRRACOIN
                                </div>
                                <div className={styles.body}>
                                    <div>Кошелек</div>
                                    <div>Майнинг</div>
                                    <div>Купить Mirracoin</div>
                                    <div>Обозреватель</div>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles.head}>
                                    P2P
                                </div>
                                <div className={styles.body}>
                                    <div>Все объявления</div>
                                    <div>Мои объявления</div>
                                    <div>Мои сделки</div>
                                </div>
                            </div>
                            <div className={styles.card}>
                                <div className={styles.head}>
                                    ОБРАЗОВАНИЕ
                                </div>
                                <div className={styles.body}>
                                    <div>Купить курс</div>
                                    <div>Активные курсы</div>
                                    <div>Лаборатория курсов</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.options}>
                            <div>Оферта</div>
                            <div>Оплата</div>
                            <div>Политика KYC/AML</div>
                            <div>Конфиденциальность</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.bottom}>
                <div className={clsx(styles.content, styles.row)}>
                    <div>
                        © 2019-2024 mirracoin.io™. Все права защищены.
                    </div>
                    <div>
                        DESIGN BY ARINA POLOZOVA
                    </div>
                </div>
            </div>
        </div>
    )
}