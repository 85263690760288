import { Block, Button, Input, Modal } from "components";
import styles from './AddedTelegram.module.scss';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTelegramIDQuery } from "lib/profile/useChangeProfileQuery";
import { FormTelegram } from "services/profile.service";
import { FaTelegramPlane } from "react-icons/fa";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useAppSelector } from "lib/useAppSelect";

type Props = {
    opened: boolean;
    close: () => void;
}

export const AddedTelegram: React.FC<Props> = ({ opened, close }) => {

    const { control, handleSubmit, formState: { errors } } = useForm<FormTelegram>();

    const { user } = useAppSelector((state) => state.user);

    const telegramId = user?.user_info?.telegram_id;

    const validateTelegramId = (value: string) => {
        const regex = /^@[a-zA-Z0-9_-]{0,32}$/;

        if (!value) {
            return "Заполните поле";
        }

        if (!regex.test(value)) {
            return "Неверный формат Telegram ID. Он должен начинаться с @ и содержать только латинские буквы, цифры, подчеркивания или тире.";
        }

        return true;
    };


    const {
        mutate: newTg,
        isSuccess,
        isLoading,
        reset
    } = useTelegramIDQuery();

    useEffect(() => {
        if (isSuccess) {
            handlerClose();
            toast.success('Telegram успешно обновлен');
        }
    }, [isSuccess]);

    const handlerClose = () => {
        close();
        reset();
    }

    const onSubmit: SubmitHandler<FormTelegram> = (data) => {
        if (!data || data?.telegram_id.length < 4) return;
        newTg({ telegram_id: data.telegram_id });
    };

    return (
        <Modal opened={opened} close={handlerClose}>
            <Block>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
                    <div className={styles.header}>
                        <h4>Введите Telegram</h4>
                    </div>
                    <div className={styles.head}>
                        <h5></h5>
                    </div>
                    <div className={styles.inputTG}>
                        <Controller
                            name="telegram_id"
                            control={control}
                            defaultValue={telegramId || "@"}
                            rules={{ validate: validateTelegramId }}
                            render={({ field }) => (
                                <Input
                                    required
                                    icon={<FaTelegramPlane />}
                                    placeholder="Telegram"
                                    type={"text"}
                                    fullWidth
                                    maxLength={100}
                                    autoFocus
                                    {...field}
                                    errorMessage={errors.telegram_id && errors.telegram_id.message}
                                    error={!!errors?.telegram_id}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.submit}>
                        <Button
                            type={"submit"}
                            isLoading={isLoading}
                            fullWidth
                        >
                            Отправить
                        </Button>
                    </div>
                </form>
            </Block>
        </Modal>
    );
};
