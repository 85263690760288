import { LoginQueryT, SignupQueryT } from 'types/auth';
import HttpService from './http.service';
import {
  getSessionToken,
  getToken,
  removeToken,
} from './token.service';

export type TokenT = {
  access_token: string;
  refresh_token: string;
};

export type RegistrationT = {
  status: boolean;
  user_info: {
    email?: string;
    password?: string;
    user_id: string;
  };
  jwt_tokens: {
    access_token: string;
    refresh_token: string;
  };
};

type LoginT = {
  message: string;
  data: {
    user_id: string;
    jwt_tokens: {
      access_token: string;
      refresh_token: string;
    };
  };
};

type VerificationEmailT = {
  message: string;
  status: boolean;
};

export type CheckVerificationEmailT = {
  code: string;
};

export type NewEmailT = {
  new_email: string;
};

const AuthService = {
  login: async (req: LoginQueryT) => {
    const { data }: { data: LoginT } = await HttpService().post('auth', req);
    return data;
  },

  verificationEmail: async () => {
    const { data }: { data: VerificationEmailT } = await HttpService().get(
      'send-email-confirmation'
    );
    return data;
  },

  checkVerificationEmail: async (req: CheckVerificationEmailT) => {
    const { data }: { data: any } = await HttpService().post(
      'check-email-confirmation',
      req
    );
    return data;
  },

  updateUserEmail: async (req: NewEmailT) => {
    const { data }: { data: any } = await HttpService().post(
      'update-user-email',
      req
    );
    return data;
  },

  fastRegistration: async () => {
    const { data }: { data: RegistrationT } =
      await HttpService().get(`fast-registration`);
    return data;
  },

  newRegistration: async (req: SignupQueryT) => {
    const { data }: { data: RegistrationT } = await HttpService().post(
      'new-registration',
      req
    );
    return data;
  },

  refresh: async () => {
    const { refreshToken } = getToken();
    const session = getSessionToken();
    try {
      const { data }: { data?: TokenT } = await HttpService().post(
        'refresh-token',
        {
          refresh_token: refreshToken || session,
        }
      );

      return data;
    } catch (e) {
      return removeToken();
    }
  },
};

export default AuthService;
