import { Block } from 'components';
import { Text } from '@mantine/core';
import { EmailCheck, PasswordChange, ListDevices } from './components';

import styles from './Security.module.scss';

export const Security: React.FC = () => {
    return (
        <>
            <Block color="dark">
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <Text size="lg" fw="bold">
                            Твои данные
                        </Text>
                    </div>
                    <EmailCheck />
                    <PasswordChange />
                </div>
            </Block>
            <Block color="dark">
                <div className={styles.wrapper}>
                    <div className={styles.settings}>
                        <div>
                            <Text size="lg" fw="bold">
                                2FA-защита
                            </Text>
                            <h5>Будешь вводить код при авторизации, выводе и совершении Р2Р сделок</h5>
                        </div>
                    </div>
                </div>
            </Block>
            <ListDevices />
        </>
    );
};
