import { Input as Field } from '@mantine/core';
import React from 'react';
import { PasswordInput } from '@mantine/core';
import styles from './InputPassword.module.scss';
import clsx from 'clsx';
import { useDisclosure } from '@mantine/hooks';
import { Text } from '@mantine/core'

type Props = {
    placeholder: string;
    label?: string;
    required?: boolean;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Add onFocus prop
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;   // Add onBlur prop
    toggle?: () => void
    autoFocus?: boolean;
    fullWidth?: boolean;
    visible?: boolean;
    icon?: React.ReactNode;
    defaultValue?: string;
    errorMessage?: string;
    error?: boolean;
    autoComplete?: "on" | "off";
};

export const InputPassword = React.forwardRef<HTMLInputElement, Props>(
    ({
        label,
        placeholder,
        required = false,
        defaultValue = "",
        value,
        onChange,
        onFocus,
        onBlur,
        autoFocus,
        fullWidth,
        icon,
        errorMessage,
        error,
        autoComplete = "off"
    }, ref) => {

        const [visible, { toggle }] = useDisclosure(false);

        const errorStyles = clsx({ [styles.error]: error || !!errorMessage })
        const errorWrapperStyles = clsx({ [styles.errorInputWrapper]: error || !!errorMessage })
        const errorSection = clsx({ [styles.errorIcon]: error || !!errorMessage })

        const wrapperStyles = { label: styles.label, root: clsx(styles.inputWrapper, errorWrapperStyles) }
        const passwordStyles = {
            innerInput: clsx({ [styles.leftIcon]: icon }),
            input: clsx(styles.input, fullWidth && styles.fullWidth, errorStyles),
            section: clsx(icon && styles.icon),
            visibilityToggle: clsx(styles.rightSection, errorSection),
            label: styles.label
        }

        return (
            <Field.Wrapper
                classNames={wrapperStyles}
                description=""
                error=""
            >
                <PasswordInput
                    label={label}
                    defaultValue={defaultValue}
                    visible={visible}
                    onVisibilityChange={toggle}
                    leftSection={icon}
                    autoFocus={autoFocus}
                    classNames={passwordStyles}
                    placeholder={placeholder}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                />
                {!!errorMessage &&
                    <p className={styles.errorMessage}>
                        <Text size={'sm'}>{errorMessage}</Text>
                    </p>
                }
            </Field.Wrapper>
        );
    }
);

InputPassword.displayName = 'InputPassword';
