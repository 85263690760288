export const BigCheckedSVG: React.FC = () => {
    return (
        <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.371094" y="0.439941" width="72.2591" height="72.2591" rx="36.1296" fill="url(#paint0_linear_2139_4237)" />
            <path d="M28.8728 52.6924C27.8973 52.6562 26.9579 52.2227 26.3076 51.464L15.6494 39.5774C14.3126 38.096 14.4571 35.7837 15.9745 34.4831C17.492 33.1824 19.7681 33.2908 21.0688 34.8083L29.0896 43.8768L51.9596 21.621C53.2964 20.1397 55.5725 19.9951 57.0538 21.2958C58.5351 22.6326 58.6797 24.9088 57.379 26.3901C57.2706 26.5346 57.1261 26.643 56.9816 26.7875L31.4018 51.7891C30.7154 52.4033 29.8122 52.7646 28.8728 52.7285V52.6924Z" fill="#33333A" />
            <defs>
                <linearGradient id="paint0_linear_2139_4237" x1="0.371094" y1="36.5695" x2="72.6302" y2="36.5695" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00C0FF" />
                    <stop offset="0.481592" stop-color="#A28AF8" />
                    <stop offset="1" stop-color="#F5F5F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}