type Props = {
    direction?: 'left' | 'right' | 'top' | 'down';
    width?: number;
}

export const ArrowSVG: React.FC<Props> = ({ direction = 'right', width = 12 }) => {
    const rotation = {
        right: '0deg',
        down: '90deg',
        left: '180deg',
        top: '270deg',
    }[direction];

    return (
        <svg
            width={width}
            height={width * 2}
            viewBox="0 0 12 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: `rotate(${rotation})` }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6897 23.2241C11.2897 23.605 10.6567 23.5896 10.2759 23.1897L0.275861 12.6897C-0.0919552 12.3034 -0.0919551 11.6965 0.275861 11.3103L10.2759 0.810343C10.6567 0.410412 11.2897 0.394974 11.6897 0.77586C12.0896 1.15675 12.105 1.78972 11.7241 2.18965L2.38095 12L11.7241 21.8103C12.105 22.2103 12.0896 22.8433 11.6897 23.2241Z"
                fill="#F5F5F5"
            />
        </svg>
    );
};
