import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import styles from './ProfileLayout.module.scss';

import { useAppSelector } from 'lib/useAppSelect';
import { SideBar, Header, ErrorFallback } from 'components';
import { useViewportHeightFix } from 'hook';
import { useEffect } from 'react';
import { useUserInfoQuery } from 'lib/users/useUsersMeQuery';
import { setUserInfo } from 'store/userStateSlice';
import { useAppDispatch } from 'lib/useAppDispatch';

type ProfileLayoutT = {
  children?: React.ReactNode;
};

export const ProfileLayout: React.FC<ProfileLayoutT> = ({ children }) => {
  const { isMobile } = useAppSelector((state) => state.isMobile);
  const dispatch = useAppDispatch();
  useViewportHeightFix();

  const { data: user } = useUserInfoQuery();

  useEffect(() => {
    if (user) {
      dispatch(setUserInfo({ user }));
    }
  }, [user, dispatch]);

  return (
    <div className={styles.layout}>
      <SideBar />
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.page}>
          <div className={styles.content}>
            {children}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      {/* {isMobile && <Menu />} */}
    </div>
  );
}
