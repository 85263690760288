import styles from './Block.module.scss';
import clsx from 'clsx';

type Props = {
    children?: React.ReactNode;
    color?: "light" | "dark"
    className?: string;
}

export const Block: React.FC<Props> = ({ children, color = "light", className }) => {
    return (
        <div className={clsx(styles.block, styles[color], className)}>
            {children}
        </div>
    )
}
