
import { Button, Checkbox, Input, InputPassword, Link, TelegramSVG } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useLoginQuery } from 'lib/auth/useAuthorizationQuery';
import { PiWarningCircleLight } from "react-icons/pi"
import { CiUser } from "react-icons/ci";
import { RiLockPasswordFill } from "react-icons/ri";
import { Text } from '@mantine/core'
import styles from './FormLogin.module.scss'
import { useEffect, useState } from 'react';
import { RouteNames } from 'navigate/routeNames';
import { Title } from '@mantine/core';
import { AiFillHome } from 'react-icons/ai';
import { TG_BOT_URL } from 'lib/consts/links';

interface LoginFormInputs {
    email: string;
    password: string;
}

export const FormLogin: React.FC = () => {
    const [rememberMe, setRememberMe] = useState(false);

    const {
        mutate: sendLoginForm,
        isLoading,
        isError,
        isSuccess
    } = useLoginQuery(rememberMe);

    const { control, handleSubmit, watch, formState: { errors } } = useForm<LoginFormInputs>();

    const email = watch("email", "");

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        if (!data.email || !data.password) return;
        sendLoginForm({ email: data.email, password: data.password });
    };

    const [errorGeneral, setErrorGeneral] = useState(false)

    useEffect(() => {
        if (!isError) return
        setErrorGeneral(true)
        if (isSuccess) {
            setErrorGeneral(false)
        }
    }, [isError])

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Title size={40}>Авторизация</Title>
                </div>
                <div className={styles.enter}>
                    <h4>Еще нет аккаунта?</h4>&nbsp;
                    <Link to={"/registration"}>
                        <h4>Создать</h4>
                    </Link>
                </div>
                <div className={styles.notif}>
                    <div className={styles.warn}>
                        <PiWarningCircleLight size={17} />
                    </div>
                    <div className={styles.block}>
                        <h6>Проект по обновлению находится в процессе, и мы постепенно&nbsp;включаем новые модули и компоненты. Информация о текущем статусе будет актуализироваться по мере завершения работы.</h6>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div className={styles.inputs}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Заполните поле" }}
                            render={({ field }) => (
                                <Input
                                    required
                                    maxLength={100}
                                    icon={<CiUser />}
                                    placeholder="Почта"
                                    type={"email"}
                                    fullWidth
                                    autoFocus={!email}
                                    {...field}
                                    errorMessage={errors.email && errors.email.message}
                                    error={!!errors?.email}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Заполните поле" }}
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    required
                                    placeholder="Пароль"
                                    {...field}
                                    errorMessage={errors.password && errors.password.message}
                                    error={!!errors?.password}
                                />
                            )}
                        />
                    </div>
                    {errorGeneral &&
                        <div className={styles.errorLogin}>
                            <Text size={"sm"}>
                                Уважаемые пользователи!
                                Если после обновления платформы
                                у вас возникли трудности с входом в
                                личный кабинет, пожалуйста, обратитесь
                                в нашу службу технической поддержки.
                                Мы с радостью поможем!
                            </Text>
                        </div>
                    }
                    <div className={styles.options}>
                        <Checkbox
                            onChange={(event) => setRememberMe(event.currentTarget.checked)}
                            checked={rememberMe}
                            label={"Запомнить"}
                        />
                        <Link to={RouteNames.SUPPORT_RESTORE} className={styles.questions}>
                            <h5>Забыл пароль?</h5>
                        </Link>
                    </div>
                    <div className={styles.login}>
                        <Button type="submit" fullWidth isLoading={isLoading}>Войти</Button>
                    </div>
                    <div className={styles.message}>
                        <h5>Система сгенерирует для вас логин и пароль, которые вы сможете изменить позже.</h5>
                    </div>
                </form>
                <div className={styles.tech}>
                    <Link
                        to={TG_BOT_URL}
                        type="empty"
                        target="_blank"
                    >
                        <div className={styles.linkTech}>
                            <div className={styles.svgTelegramm}>
                                <TelegramSVG />
                            </div>
                            <h5>
                                Техническая поддержка
                            </h5>
                        </div>
                    </Link>
                    <div className={styles.home}>
                        <Link
                            to={RouteNames.START}
                            type="empty"
                        >
                            <AiFillHome size={20} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}