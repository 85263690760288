import { Block, Button } from 'components';
import img from 'assets/images/user.png';
import { FaChevronRight } from "react-icons/fa";
import clsx from 'clsx';
import { useAppSelector } from 'lib/useAppSelect';
import { useDisclosure } from '@mantine/hooks';
import { MRC_EMAIL } from 'consts';
import { ModalEmail } from '../security/components/EmailCheck/components';
import { ModalViewInfoEmail } from './components';
import { useEffect } from 'react';
import { NBSP } from 'consts/consts';

import styles from './Profile.module.scss';
import { ListDevices } from '../security/components';

const status = [
    {
        type: "Не верифицирован",
        low: "Низкая",
        color: "red",
    },
    {
        type: "Верифицирован",
        low: "Высока",
        color: "green",
    }
];

export const Profile: React.FC = () => {
    const { user } = useAppSelector((state) => state.user);
    const { userFastDate } = useAppSelector((state) => state.userFastDate)
    const statusUser = user?.user_info?.code_2FA_enable ? 1 : 0
    const isEmailMirracoin = user?.user_info?.email.includes(MRC_EMAIL);

    const [email, { open, close }] = useDisclosure(false);

    const isCheckVerificationEmail = user && !user?.user_info?.verification_email;

    useEffect(() => {
        if (isCheckVerificationEmail && !isEmailMirracoin) return;
        if (userFastDate) {
            open();
            return;
        }
    }, [])

    return <div className={styles.content}>
        {
            !userFastDate && isCheckVerificationEmail && !isEmailMirracoin &&
            <ModalEmail
                close={close}
                opened={email}
            />
        }
        {
            !!userFastDate &&
            <ModalViewInfoEmail
                close={close}
                opened={email}
            />
        }
        {isCheckVerificationEmail && !isEmailMirracoin &&
            <Block>
                <div className={styles.row}>
                    <div className={styles.options}>
                        <img src={img} alt='user'/>
                        <div className={styles.text}>
                            <h5>Верифицируйте ваш e-mail</h5>
                            <h5>Подтвердите e-mail чтобы не потерять доступ к аккаунту</h5>
                        </div>
                    </div>
                    <Button onClick={open} type='button'>Подтвердить email</Button>
                </div>
            </Block>
        }
        <Block color='dark'>
            <div className={clsx(styles.security)}>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Верификация личности</h6>
                    </div>
                    <div className={styles.verified}>
                        <div className={clsx(styles.color, styles[`color_${status[statusUser].color}`])}></div>
                        <h5>{status[statusUser].type}</h5>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Безопасность{NBSP}<FaChevronRight size={9} /></h6>
                    </div>
                    <div className={clsx(styles.status, styles[`status_${status[statusUser].color}`])}>
                        <h5>{status[statusUser].low}</h5>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Последний вход{NBSP}<FaChevronRight size={9} /></h6>
                    </div>
                    <div className={styles.browser}>
                        <h5>
                            {user && user?.user_info?.recent_auth_devices?.device_info}
                            {user && !user?.user_info?.recent_auth_devices?.device_info && "Информация о последнем входе отсутствует"}
                        </h5>
                    </div>
                </div>
            </div>
        </Block>
        <ListDevices/>
    </div>
}
