import React, { useState } from 'react';
import { Switch as MantineSwitch } from '@mantine/core';
import styles from './Switch.module.scss';

type Props = {
  disabled?: boolean;
  label?: string;
  checked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isLoading?: boolean
};

export const Switch: React.FC<Props> = ({ label = "", disabled, checked = false, onChange, isLoading }) => {
  return (
    <MantineSwitch
      checked={checked}
      onChange={!isLoading && onChange || undefined}
      // (event) => setChecked(
      // event.currentTarget.checked)}
      label={label}
      disabled={disabled}
      classNames={{
        root: styles.root,
        track: styles.track,
        thumb: styles.thumb,
        input: styles.switch,
      }}
    />
  );
};
