import { Routers } from 'navigate/routers';
import React from 'react';
import { useAppDispatch } from "lib/useAppDispatch";
import { useEffect } from "react";
import { getSessionToken, getToken } from "services";
import { toggleAuthState } from "store/authStateSlice";
import { useAppSelector } from 'lib/useAppSelect';
import { useDeviceTypeIdentifier } from 'lib/useDeviceTypeIdentifier';

export const App: React.FC = () => {
  const { accessToken, refreshToken } = getToken();
  const session = getSessionToken();
  
  const dispatch = useAppDispatch();
  const { isLogin } = useAppSelector((state) => state.authState);


  useDeviceTypeIdentifier();
  useEffect(() => {
    if (accessToken || refreshToken || session) {
      dispatch(toggleAuthState({ isLogin: true }));
    }
    if (!refreshToken && !session) {
      dispatch(toggleAuthState({ isLogin: false }));
    }
  }, []);

  if (isLogin === undefined) {
    return null;
  }


  return <Routers />
};
