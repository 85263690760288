import HttpService from './http.service';

export type SendEmailRecoveryPasswordT = {
  email: string;
};

export type SendPasswordRecoveryPasswordT = {
  email: string;
  token: string;
  password: string;
};

const RecoveryService = {
  sendEmail: async (req: SendEmailRecoveryPasswordT) => {
    const { data }: { data: any } = await HttpService().post(
      'password-recovery',
      req
    );
    return data;
  },
  sendPassword: async (req: SendPasswordRecoveryPasswordT) => {
    const { data }: { data: any } = await HttpService().post(
      'new-password-user-for-recovery',
      req
    );
    return data;
  },
};

export default RecoveryService;
