import { Block, Button, Input, PostSVG } from "components";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NewEmailT } from "services/auth.service";
import { useUpdateUserEmailQuery } from "lib/auth/useAuthorizationQuery";
import { NBSP } from "consts/consts";

import styles from './UpdateUserEmail.module.scss';

type Props = {
    onChange: () => void;
}

export const UpdateUserEmail: React.FC<Props> = ({ onChange }) => {
    const { control, handleSubmit, clearErrors, formState: { errors } } = useForm<NewEmailT>();
    const {
        mutate: updateUserEmail,
        isSuccess,
        isLoading,
        reset
    } = useUpdateUserEmailQuery();

    useEffect(() => {
        if (isSuccess) {
            handlerClose();
        }
    }, [isSuccess]);

    const handlerClose = () => {
        onChange();
        reset();
        clearErrors();
    }

    const onSubmit: SubmitHandler<NewEmailT> = (data) => {
        if (!data) return;
        updateUserEmail(data);
    };

    return (
        <Block>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
                <PostSVG />
                <div className={styles.header}>
                    <h4>Укажите e-mail</h4>
                </div>
                <div className={styles.head}>
                    <h5>
                        Укажите{NBSP}адрес{NBSP}электронной{NBSP}почты,{NBSP}чтобы{NBSP}иметь{NBSP}возможность восстановить{NBSP}доступ{NBSP}к{NBSP}аккаунту
                          и получить уведомления безопасности
                    </h5>
                </div>
                <div className={styles.inputs}>
                    <Controller
                        name="new_email"
                        control={control}
                        rules={{ required: "Заполните поле" }}
                        defaultValue=""
                        render={({ field }) => (
                            <Input
                                maxLength={100}
                                placeholder="Укажите почту"
                                type={"email"}
                                fullWidth
                                autoFocus
                                {...field}
                                errorMessage={errors.new_email && errors.new_email.message}
                                error={!!errors.new_email}
                            />
                        )}
                    />
                </div>
                <div className={styles.submit}>
                    <Button type={"submit"} fullWidth isLoading={isLoading}>Изменить</Button>
                </div>
            </form>
        </Block>
    );
};
