export const secretsEmail = (email?: string): string => {
  if (!email) return '';
  const [local, domain] = email.split('@');

  if (!local || !domain) return '';
  const localObfuscated =
    local.length > 4 ? `${local.slice(0, 4)}•••` : `${local}•••`;

  const domainParts = domain.split('.');
  const domainName = domainParts[0];
  const domainObfuscated =
    domainName.length > 3 ? `•••${domainName.slice(-1)}` : `•••${domainName}`;

  const domainExtension = domainParts.slice(1).join('.');
  return `${localObfuscated}@${domainObfuscated}.${domainExtension}`;
};
