import styles from './Sessions.module.scss';
import { Text } from '@mantine/core'
import { DeviceT } from 'services/profile.service';

type Props = {
    device: DeviceT;
}

export const Sessions: React.FC<Props> = ({ device }) => {
    return (
        <div className={styles.content}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>Клиент</h5>
                        <div className={styles.cell}>
                            <Text size="lg">{device.device_info}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>IP</h5>
                        <div className={styles.cell}>
                            <Text size="lg">{device.client_ip}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>Дата и время</h5>
                        <div className={styles.cell}>
                            <Text size='lg'>
                                {new Date(device.timestamp * 1000).toLocaleString('ru-RU', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}