import { useAppDispatch } from 'lib/useAppDispatch';
import { RouteNames } from 'navigate/routeNames';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getSessionToken, getToken, removeToken, setToken } from 'services';
import AuthService from 'services/auth.service';
import usersService from 'services/users.service';
import { toggleAuthState } from 'store/authStateSlice';
import { setUserInfo } from 'store/userStateSlice';

export const useUserInfoQuery = () => {
  const { refreshToken } = getToken();
  const session = getSessionToken();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useQuery(['userInfo'], async () => await usersService.userInfo(), {
    enabled: !!refreshToken || !!session,
    retry: 2,
    onError: async () => {
      const data = await AuthService.refresh();
      if (!!data && refreshToken) {
        if ('access_token' in data) {
          setToken(data?.access_token, refreshToken);
          return;
        }
      }

      if (!refreshToken) {
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.PROFILE);
        dispatch(setUserInfo({ user: undefined }));
        removeToken();
        return;
      }
    },
  });
};
