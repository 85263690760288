import { createSlice } from '@reduxjs/toolkit';
import { UserT } from 'services/users.service';

const initialState: {
  user: UserT | undefined;
} = {
  user: undefined,
};

const userStateSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(
      state: { user: UserT | undefined },
      action: {
        payload: {
          user: UserT | undefined;
        };
      }
    ) {
      state.user = action.payload.user;
    },
  },
});

export default userStateSlice.reducer;
export const { setUserInfo } = userStateSlice.actions;
