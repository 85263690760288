import { Title } from '@mantine/core'
import styles from './BlockTitle.module.scss'
import { Button, TelegramSVG } from 'components'
import { useAppSelector } from 'lib'
import { Link } from 'react-router-dom'
import { RouteNames } from 'navigate/routeNames'
import { TG_BOT_URL } from 'lib/consts/links'

const listButtons = [
    {
        link: "",
        label: "О нас"
    },
    {
        link: "",
        label: "Кошелек"
    },
    {
        link: "",
        label: "Обозреватель"
    },
    {
        link: "",
        label: "NFT"
    },
    {
        link: "",
        label: "Mirra AI",
    }
]


export const BlockTitle: React.FC = () => {
    const { isLogin } = useAppSelector((state) => state.authState);

    return (
        <div className={styles.wrapper}>
            <div className={styles.buttons}>
                {
                    listButtons.map((item) => <div key={item.link}>
                        <Button color={"gradient"} className={styles.buttonNext}>{item.label}</Button>
                    </div>)
                }
            </div>
            <div className={styles.text}>
                <Title size={50}>MirraCoin — контроль</Title>
                <Title size={50}>за активами в один клик</Title>
            </div>
            <div className={styles.next}>
                {!isLogin &&
                    <>
                        <Link to={RouteNames.REGISTRATION}>
                            <Button className={styles.regist}>Зарегистироваться</Button>
                        </Link>
                        <Link
                            to={TG_BOT_URL}
                            type="empty"
                            target="_blank"
                            className={styles.telegram}
                        >
                                <TelegramSVG width={28} />
                        </Link>
                    </>
                }
            </div>
        </div>
    )
}