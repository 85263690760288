import { createSlice } from '@reduxjs/toolkit';
import { RegistrationT } from 'services/auth.service';

const initialState: {
  userFastDate: RegistrationT | undefined;
} = {
  userFastDate: undefined,
};

const authStateSlice = createSlice({
  name: 'userFastDate',
  initialState,
  reducers: {
    setFastRegistrationDate(
      state: { userFastDate: RegistrationT | undefined },
      action: {
        payload: {
          userFastDate: RegistrationT | undefined;
        };
      }
    ) {
      state.userFastDate = action.payload.userFastDate;
    },
  },
});

export default authStateSlice.reducer;
export const { setFastRegistrationDate } = authStateSlice.actions;
