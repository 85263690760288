import line_mini from 'assets/images/line_mini.png';
import line_middle from 'assets/images/line_middle.png';
import arrow_mouse from 'assets/images/arrow_mouse.png';


import styles from './ReferenceBlock.module.scss'
import { useAppSelector } from 'lib';
import { Button } from 'components';
import { Link } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';
import { NBSP } from 'consts/consts';

export const ReferenceBlock: React.FC = () => {
    const { isMobile } = useAppSelector((state) => state.isMobile);
    return (
        <div className={styles.wrapper}>
            <Link to={RouteNames.REGISTRATION} className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.head}>
                        Реферальная программа
                    </h1>
                    <h4 className={styles.body}>
                        При регистрации мы предоставляем бонус{NBSP}
                        {!isMobile && <br />}
                        в размере <span className={styles.gradient}>
                            <span><span>1000{NBSP}Mirracoin</span></span>
                            </span>, которые{NBSP}вы можете
                        {!isMobile && <br />}
                        {isMobile && NBSP}
                        немедленно использовать на нашем портале
                    </h4>
                </div>
                <div className={styles.registration}>
                    <Button className={styles.button}>
                        <h4>Зарегистрироваться</h4>
                    </Button>
                    <img src={arrow_mouse} alt={'arrow mouse'} className={styles.arrowMouse} />
                </div>
                {!isMobile && <div className={styles.lines}>
                    <img src={line_mini} alt={'line mini'} className={styles.miniLine} />
                    <img src={line_middle} alt={'line middle'} className={styles.middleLine} />
                </div>
                }
            </Link>
        </div>
    )
}