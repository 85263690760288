import { PasswordRecovery } from "feature/passwordRecovery";
import { useSearchParams } from "react-router-dom";


export const PasswordRecoveryPage: React.FC = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    return <PasswordRecovery token={token} email={email} />
}

