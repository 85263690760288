export const LogoNewTechnologySVG: React.FC = () => {
    return (
        <svg width="186" height="32" viewBox="0 0 186 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.8247 18.008L32.6598 9.08956L35.6523 3.82486C36.425 2.46432 37.8304 1.63025 39.3494 1.63025H47.3136L37.8247 18.008Z" fill="url(#paint0_linear_1_283)" />
            <path d="M5.73924 28.3518L1.22551 21.1288C0.616847 20.1545 0.61283 18.9287 1.21546 17.9504L10.705 2.57316C11.0665 1.98777 11.7134 1.63025 12.4104 1.63025H20.313C21.2008 1.63025 21.7472 2.57905 21.2872 3.32159L5.73924 28.3518Z" fill="url(#paint1_linear_1_283)" />
            <path d="M55.3743 28.3518L38.6937 1.63025H48.6664C49.3684 1.63025 50.0218 1.98777 50.3838 2.57119L59.6648 17.4907C60.4396 18.7362 60.4355 20.2959 59.6567 21.5354L55.3743 28.3499V28.3518Z" fill="url(#paint2_linear_1_283)" />
            <path d="M13.696 1.63025H22.2884C23.6841 1.63025 24.9759 2.3692 25.6917 3.57858L36.1077 20.95L30.6888 30.0758L13.696 1.63025Z" fill="url(#paint3_linear_1_283)" />
            <path d="M88.515 0.00476074C89.4641 0.00476074 89.9549 0.489275 89.9912 1.45643L90.6768 17.8848H86.9624L86.357 3.06396H85.8834L82.9577 14.2059C82.7992 15.0341 82.2721 15.4491 81.3765 15.4491H78.8461C77.9504 15.4491 77.4062 15.0341 77.2133 14.2059L74.2895 3.06396H73.8407L73.2869 17.8848H69.5458L70.2314 1.45643C70.2657 0.489275 70.7661 0.00476074 71.7324 0.00476074H75.5003C76.3615 0.00476074 76.8886 0.42918 77.0815 1.27426L79.4266 10.2659C79.5317 10.681 79.6195 11.0998 79.6902 11.5223C79.7608 11.9467 79.8391 12.373 79.927 12.8049H80.2688C80.3567 12.373 80.4407 11.9448 80.519 11.5223C80.5973 11.0998 80.6813 10.6716 80.7692 10.2397L83.0876 1.27426C83.2633 0.427302 83.7903 0.00476074 84.6688 0.00476074H88.515Z" fill="white" />
            <path d="M96.0023 0.00476074C96.7872 0.00476074 97.1786 0.381509 97.1786 1.13501V2.46546C97.1786 3.21895 96.7872 3.5957 96.0023 3.5957H94.2912C93.5064 3.5957 93.115 3.21895 93.115 2.46546V1.13501C93.115 0.381509 93.5064 0.00476074 94.2912 0.00476074H96.0023ZM97.0992 5.47944V17.8848H93.1692V5.47944H97.0992Z" fill="white" />
            <path d="M103.599 5.21572L104.09 7.72909L104.472 8.06185V17.8848H100.43V5.21572H103.597H103.599ZM110.182 4.8811L109.8 8.39461H108.68C108.042 8.39461 107.377 8.48012 106.686 8.65115C105.993 8.82218 105.12 9.07872 104.063 9.42078L103.789 7.29222C104.736 6.50587 105.692 5.90727 106.658 5.49643C107.622 5.08559 108.561 4.8811 109.472 4.8811H110.182Z" fill="white" />
            <path d="M114.713 5.21572L115.163 7.72909L115.513 8.06185V17.8848H111.808V5.21572H114.711H114.713ZM120.748 4.8811L120.398 8.39461H119.371C118.786 8.39461 118.177 8.48012 117.543 8.65115C116.908 8.82218 116.108 9.07872 115.139 9.42078L114.889 7.29222C115.756 6.50587 116.632 5.90727 117.518 5.49643C118.402 5.08559 119.262 4.8811 120.096 4.8811H120.748Z" fill="white" />
            <path d="M133.008 10.2869L132.982 12.4966L127.372 12.5237C126.913 12.5237 126.584 12.6395 126.39 12.8691C126.194 13.1007 126.096 13.4114 126.096 13.8013V14.4401C126.096 14.884 126.214 15.2256 126.453 15.4649C126.691 15.7042 127.074 15.8238 127.601 15.8238C128.043 15.8238 128.523 15.7254 129.043 15.5305C129.56 15.3356 130.076 15.0596 130.586 14.7045C131.096 14.3494 131.547 13.9325 131.937 13.452V15.1561C131.784 15.4224 131.559 15.7563 131.261 16.1539C130.963 16.5534 130.584 16.9432 130.126 17.3253C129.667 17.7075 129.131 18.0317 128.52 18.298C127.908 18.5644 127.209 18.6975 126.429 18.6975C125.649 18.6975 124.976 18.5547 124.362 18.271C123.75 17.9873 123.266 17.5704 122.909 17.0185C122.553 16.4684 122.373 15.7852 122.373 14.9689V13.6102C122.373 12.5623 122.713 11.7459 123.394 11.1611C124.074 10.5744 125.009 10.283 126.2 10.283H133.01L133.008 10.2869ZM129.642 4.8811C130.695 4.8811 131.588 5.02778 132.32 5.32113C133.05 5.61448 133.609 6.11047 133.991 6.81297C134.374 7.51354 134.564 8.47658 134.564 9.70209V18.3829H131.555L131.019 15.5344L130.764 15.1349V9.67507C130.764 9.05363 130.623 8.60202 130.342 8.31639C130.061 8.03269 129.531 7.88988 128.749 7.88988C128.102 7.88988 127.296 7.91303 126.325 7.95549C125.357 7.99988 124.362 8.05778 123.342 8.12919L122.985 5.51991C123.597 5.3964 124.303 5.29025 125.102 5.19954C125.9 5.11077 126.699 5.0355 127.499 4.97374C128.298 4.91198 129.013 4.8811 129.642 4.8811Z" fill="white" />
            <path d="M143.455 4.8811C143.919 4.8811 144.428 4.90426 144.977 4.94671C145.527 4.99109 146.083 5.07021 146.642 5.18599C147.201 5.30177 147.713 5.46579 148.177 5.67806L147.764 8.15382C147.075 8.13645 146.417 8.11909 145.789 8.09979C145.161 8.08242 144.598 8.07277 144.099 8.07277C143.324 8.07277 142.713 8.17891 142.266 8.3931C141.819 8.60536 141.496 8.98358 141.299 9.52388C141.101 10.0661 141.002 10.8148 141.002 11.7739C141.002 12.7329 141.101 13.5144 141.299 14.0644C141.498 14.6143 141.819 14.9964 142.266 15.2087C142.713 15.4209 143.324 15.529 144.099 15.529C144.373 15.529 144.727 15.5194 145.157 15.502C145.587 15.4846 146.051 15.4615 146.55 15.4364C147.049 15.4094 147.539 15.3611 148.02 15.2897L148.381 17.7925C147.693 18.1128 146.911 18.3425 146.032 18.4853C145.155 18.6281 144.286 18.6975 143.425 18.6975C141.844 18.6975 140.583 18.4718 139.646 18.0183C138.71 17.5648 138.035 16.8335 137.621 15.8223C137.208 14.8112 137.002 13.4623 137.002 11.7758C137.002 10.0893 137.214 8.74044 137.634 7.72929C138.055 6.71815 138.74 5.99066 139.686 5.54684C140.632 5.10302 141.887 4.8811 143.452 4.8811H143.455Z" fill="#B78BF7" />
            <path d="M156.52 4.8811C158.103 4.8811 159.373 5.10687 160.327 5.56035C161.28 6.01382 161.965 6.74516 162.384 7.75631C162.801 8.76745 163.01 10.1259 163.01 11.8298C163.01 13.5337 162.801 14.8382 162.384 15.8493C161.965 16.8605 161.28 17.5899 160.327 18.0318C159.373 18.4756 158.105 18.6975 156.52 18.6975C154.935 18.6975 153.689 18.4756 152.726 18.0318C151.763 17.588 151.069 16.8605 150.645 15.8493C150.219 14.8382 150.006 13.4971 150.006 11.8298C150.006 10.1626 150.219 8.76745 150.645 7.75631C151.071 6.74516 151.765 6.01189 152.726 5.56035C153.689 5.10687 154.952 4.8811 156.52 4.8811ZM156.52 7.75631C155.872 7.75631 155.361 7.87209 154.987 8.10172C154.613 8.33328 154.348 8.74044 154.194 9.32706C154.041 9.91368 153.965 10.7473 153.965 11.8298C153.965 12.9124 154.041 13.7209 154.194 14.3056C154.348 14.8922 154.611 15.2955 154.987 15.5174C155.361 15.7393 155.872 15.8493 156.52 15.8493C157.168 15.8493 157.677 15.7393 158.053 15.5174C158.427 15.2955 158.692 14.8922 158.846 14.3056C158.999 13.719 159.075 12.895 159.075 11.8298C159.075 10.7647 158.999 9.91368 158.846 9.32706C158.692 8.74044 158.427 8.33328 158.053 8.10172C157.679 7.87209 157.168 7.75631 156.52 7.75631Z" fill="#B78BF7" />
            <path d="M169.148 0.00476074C169.933 0.00476074 170.324 0.381509 170.324 1.13501V2.46546C170.324 3.21895 169.933 3.5957 169.148 3.5957H167.437C166.652 3.5957 166.261 3.21895 166.261 2.46546V1.13501C166.261 0.381509 166.652 0.00476074 167.437 0.00476074H169.148ZM170.243 5.47944V17.8848H166.313V5.47944H170.243Z" fill="#B78BF7" />
            <path d="M176.571 5.21572L177.001 7.72909L177.304 8.06185V17.8848H173.575V5.21572H176.573H176.571ZM182.239 4.8811C184.59 4.8811 185.766 6.05227 185.766 8.39461V17.8848H182.038V9.57507C182.038 9.00994 181.938 8.62141 181.735 8.40762C181.534 8.19384 181.189 8.08787 180.701 8.08787C180.165 8.08787 179.61 8.1957 179.038 8.40762C178.467 8.62141 177.762 8.95045 176.922 9.39475L176.745 7.2662C177.634 6.49657 178.547 5.90727 179.478 5.49643C180.409 5.08559 181.33 4.8811 182.237 4.8811H182.239Z" fill="#B78BF7" />
            <path d="M181.303 31.9953V31.1232H184.484L184.843 30.7641V29.6868H184.679L184.371 29.9433H182.124L181.047 28.866V24.8132H181.97V28.5069L182.534 29.0712H184.114L184.843 28.4658V24.8132H185.766V31.1232L184.894 31.9953H181.303Z" fill="white" />
            <path d="M174.942 31.9953V31.1232H178.276L178.635 30.7641V29.5842H178.451L178.02 29.9433H175.763L174.583 28.7634V25.9931L175.763 24.8132H179.559V31.1232L178.687 31.9953H174.942ZM176.173 29.0712H177.763L178.635 28.353V25.6853H176.173L175.506 26.3522V28.4043L176.173 29.0712Z" fill="white" />
            <path d="M169.25 29.9433L168.07 28.7634V25.9931L169.25 24.8132H172.071L173.251 25.9931V28.7634L172.071 29.9433H169.25ZM169.66 29.0712H171.661L172.328 28.4043V26.3522L171.661 25.6853H169.66L168.993 26.3522V28.4043L169.66 29.0712Z" fill="white" />
            <path d="M165.663 29.9433V23.6333H164.637V22.7612H166.586V29.9433H165.663Z" fill="white" />
            <path d="M160.022 29.9433L158.842 28.7634V25.9931L160.022 24.8132H162.843L164.023 25.9931V28.7634L162.843 29.9433H160.022ZM160.432 29.0712H162.433L163.1 28.4043V26.3522L162.433 25.6853H160.432L159.765 26.3522V28.4043L160.432 29.0712Z" fill="white" />
            <path d="M152.582 29.9433V24.8132H153.3V25.2236H153.485L153.977 24.8132H156.378L157.456 25.8905V29.9433H156.532V26.2496L155.968 25.6853H154.234L153.506 26.2907V29.9433H152.582Z" fill="white" />
            <path d="M146.221 29.9433V22.7612H147.144V25.1416H147.319L147.719 24.8132H150.017L151.094 25.8906V29.9433H150.171V26.2497L149.607 25.6853H147.975L147.144 26.3728V29.9433H146.221Z" fill="white" />
            <path d="M141.606 29.9433L140.426 28.7634V25.9931L141.606 24.8132H144.889V25.6853H142.016L141.349 26.3522V28.4043L142.016 29.0712H144.889V29.9433H141.606Z" fill="white" />
            <path d="M135.294 29.9433L134.114 28.7634V25.9931L135.294 24.8132H138.012L139.192 25.9931V27.8913H135.037V28.4043L135.704 29.0712H138.833V29.9433H135.294ZM135.037 27.0191H138.269V26.3522L137.602 25.6853H135.704L135.037 26.3522V27.0191Z" fill="white" />
            <path d="M130.369 29.9433V23.6333H127.548V22.7612H134.165V23.6333H131.344V29.9433H130.369Z" fill="white" />
            <path d="M118.776 29.9433V24.8132H119.494V25.2236H119.679L120.171 24.8132H122.572L123.65 25.8905V29.9433H122.726V26.2496L122.162 25.6853H120.428L119.699 26.2907V29.9433H118.776Z" fill="white" />
            <path d="M116.311 29.9433V24.8132H117.235V29.9433H116.311ZM116.26 23.7872V22.7612H117.286V23.7872H116.26Z" fill="white" />
            <path d="M110.825 29.9433L109.953 29.0712V27.6861L110.927 26.7113H113.903V26.1983L113.39 25.6853H110.62V24.8132H113.8L114.826 25.8392V29.9433H114.108V29.4303H113.923L113.287 29.9433H110.825ZM111.235 29.0712H113.031L113.903 28.3632V27.5834H111.338L110.876 28.0452V28.7121L111.235 29.0712Z" fill="white" />
            <path d="M103.848 29.9433V22.7612H104.771V25.1416H104.946L105.346 24.8132H107.644L108.722 25.8906V29.9433H107.798V26.2497L107.234 25.6853H105.603L104.771 26.3728V29.9433H103.848Z" fill="white" />
            <path d="M99.233 29.9433L98.0531 28.7634V25.9931L99.233 24.8132H102.516V25.6853H99.6434L98.9765 26.3522V28.4043L99.6434 29.0712H102.516V29.9433H99.233Z" fill="white" />
            <path d="M92.4157 29.9433V22.7612H93.3391V26.8653H94.6934L96.1503 24.8132H97.0224V25.121L95.4937 27.2757L97.2277 29.6355V29.9433H96.3042L94.6934 27.7374H93.3391V29.9433H92.4157Z" fill="white" />
            <path d="M87.8007 29.9433L86.6208 28.7634V25.9931L87.8007 24.8132H91.0839V25.6853H88.2111L87.5442 26.3522V28.4043L88.2111 29.0712H91.0839V29.9433H87.8007Z" fill="white" />
            <path d="M81.2879 29.9433L80.108 28.7634V25.9931L81.2879 24.8132H84.1094L85.2893 25.9931V28.7634L84.1094 29.9433H81.2879ZM81.6983 29.0712H83.699L84.3659 28.4043V26.3522L83.699 25.6853H81.6983L81.0314 26.3522V28.4043L81.6983 29.0712Z" fill="white" />
            <path d="M77.7005 29.9433V23.6333H76.6745V22.7612H78.6239V29.9433H77.7005Z" fill="white" />
            <path d="M69.5458 29.9433V22.7612H74.5732L75.7018 23.8898V25.3775L75.0349 26.0445L76.0609 27.0705V28.7121L74.8297 29.9433H69.5458ZM70.5205 25.788H74.0602L74.7271 25.121V24.2489L74.1115 23.6333H70.5205V25.788ZM70.5205 29.0712H74.368L75.0862 28.353V27.3783L74.368 26.6601H70.5205V29.0712Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1_283" x1="39.9867" y1="1.63025" x2="39.9173" y2="12.6723" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8C64D8" />
                    <stop offset="1" stop-color="#B78BF7" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_283" x1="11.1101" y1="28.3518" x2="11.1101" y2="1.63025" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6EE3FE" />
                    <stop offset="1" stop-color="#0086FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_283" x1="49.4685" y1="1.63025" x2="49.4685" y2="28.3518" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#B78BF7" />
                    <stop offset="1" stop-color="#8C64D8" />
                </linearGradient>
                <linearGradient id="paint3_linear_1_283" x1="24.9019" y1="1.63025" x2="24.9019" y2="30.0758" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00C0FF" />
                    <stop offset="1" stop-color="#6EE3FE" />
                </linearGradient>
            </defs>
        </svg>
    )
}