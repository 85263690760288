import { queryClientBase } from 'index';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import profileService, {
  FormTelegram,
  NewPasswordT,
} from 'services/profile.service';

export const useChangeNewPasswordQuery = () => {
  return useMutation(
    async (data: NewPasswordT) => await profileService.newPassword(data),
    {
      retry: false,
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useTelegramIDQuery = () => {
  return useMutation(
    async (data: FormTelegram) => await profileService.telegram(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
    }
  );
};

export const useUpdateTelegramNotificationsQuery = () => {
  return useMutation(
    async (data: { status: boolean }) =>
      await profileService.updateTelegramNotifications(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useUpdateFinancialTransactionsNotificationsQuery = () => {
  return useMutation(
    async (data: { status: boolean }) =>
      await profileService.updateFinancialTransactionsNotifications(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage);
      },
    }
  );
};

export const useUpdateReferralProgramNotificationsQuery = () => {
  return useMutation(
    async (data: { status: boolean }) =>
      await profileService.updateReferralProgramNotifications(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage, {
          id: 'unique-error',
          duration: 3000,
        });
      },
    }
  );
};

export const useUpdateNewsNotificationsQuery = () => {
  return useMutation(
    async (data: { status: boolean }) =>
      await profileService.updateNewsNotifications(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage, {
          id: 'unique-error',
          duration: 3000,
        });
      },
    }
  );
};

export const useUpdatePromotionalOffersNotificationsQuery = () => {
  return useMutation(
    async (data: { status: boolean }) =>
      await profileService.updatePromotionalOffersNotifications(data),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage, {
          id: 'unique-error',
          duration: 3000,
        });
      },
    }
  );
};

export const useDisableAllNotificationsUserQuery = () => {
  return useMutation(
    async () => await profileService.disableAllNotificationsUser(),
    {
      retry: false,
      onSuccess: () => {
        queryClientBase.refetchQueries(['userInfo']);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || 'An unexpected error occurred.';
        toast.error(errorMessage, {
          id: 'unique-error',
          duration: 3000,
        });
      },
    }
  );
};

export const useGetRecentAuthDevicesQuery = () => {
  return useQuery(
    ['getRecentAuthDevices'],
    async () => await profileService.getRecentAuthDevices(),
    {
      retry: 2,
    }
  );
};
