type Props = {
    width?: number
    color?: string
}

export const TelegramSVG: React.FC<Props> = ({width=12, color="#F5F5F5"}) => {
    return (
        <svg width={width} height={width} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.01303 4.99746L10.7685 0.918866C11.2214 0.741443 11.6166 1.03843 11.47 1.78113L11.4708 1.78027L9.80963 10.2653C9.68672 10.867 9.3567 11.0131 8.89547 10.7299L6.36601 8.70836L5.14594 9.98289C5.01117 10.129 4.89734 10.2525 4.6357 10.2525L4.81513 7.46125L9.50294 2.86881C9.70688 2.67424 9.45749 2.56453 9.18834 2.75824L3.39508 6.71341L0.897628 5.86872C0.355375 5.6823 0.343518 5.28074 1.01224 4.99746H1.01303Z" fill={color} />
        </svg>
    )
}
