import { ArrowLineSVG, Block, ExchangeSVG } from 'components';
import styles from './TableTransactions.module.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button, Checkbox, Input, InputPassword, Link, TelegramSVG } from 'components';
import { CiUser } from 'react-icons/ci';
import { BaseInput } from 'components/BaseInput';
import { SearchSVG } from 'components/SVG/SearchSVG';
import { Text, Title } from '@mantine/core';
import clsx from 'clsx';
import { TableRow } from './TableRow';

type SearchT = {
    search: string;
}

export const TableTransactions: React.FC = () => {
    const { control, handleSubmit, watch, formState: { errors } } = useForm<SearchT>();
    return (
        <Block color="dark" className={styles.blockTable}>
            <section className={styles.sectionTop}>
                <div className={styles.search}>
                    <Controller
                        name="search"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <BaseInput
                                required
                                maxLength={100}
                                icon={<SearchSVG />}
                                placeholder="Поиск монет"
                                type={"text"}
                                fullWidth
                                {...field}
                                errorMessage={errors.search && errors.search.message}
                            />
                        )}
                    />
                </div>
                <div className={styles.checked}>
                    <Checkbox
                        color={'white'}
                        checked={false}
                        label={"Скрыть нулевые счета"}
                    />
                </div>
            </section>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>
                            <Text size='xs' fw={450}>Монеты</Text>
                        </th>
                        <th>
                            <Text size='xs' fw={450}>Баланс</Text>
                        </th>
                        <th>
                            <Text size='xs' fw={450}>Стоимость</Text>
                        </th>
                        <th>
                            <Text size='xs' fw={450}>Курс</Text>
                        </th>
                        <th>
                            <Text size='xs' fw={450}>Изменение цены</Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                </tbody>
            </table>
        </Block >
    )
}