import React from "react";
import clsx from "clsx";
import { Title, Text } from "@mantine/core";
import styles from "./TableRow.module.scss";


type Props = {
  coinName?: string
  coinId?: string
  balance?: string
  price?: string
  rate?: string,
  changePercent?: number
}

export const TableRow: React.FC<Props> = ({
  coinName = "USDT",
  coinId = "Tether",
  balance = "0 USDT",
  price = "$0.00",
  rate = "$1.00",
  changePercent = 0,
}) => {
  return (
    <tr>
      <td>
        <div className={styles.cellContent}>
          <div className={styles.typeCoin}>
            <div className={styles.icon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <g clip-path="url(#clip0_56_108)">
                  <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="#1BA27A" />
                  <path d="M24.164 9.39569H11.6938V12.8076H16.4237V17.8226H19.4342V12.8076H24.164V9.39569Z" fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9576 15.0291C22.351 15.0291 25.9126 15.934 25.9126 17.0505C25.9126 18.0387 23.122 18.8612 19.4341 19.037V26.2333H16.4239V19.0342C12.7642 18.8527 10.0024 18.0336 10.0024 17.0505C10.0024 15.934 13.5642 15.0291 17.9576 15.0291ZM10.8728 16.7891C10.8728 17.6549 14.0449 18.3567 17.9576 18.3567C21.8702 18.3567 25.0422 17.6549 25.0422 16.7891C25.0422 15.9234 21.8702 15.2215 17.9576 15.2215C14.0448 15.2215 10.8728 15.9234 10.8728 16.7891Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_56_108">
                    <rect width="36" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className={styles.boxNames}>
              <div>
                <Title size={15} fw={600}>
                  {coinName}
                </Title>
              </div>
              <div className={styles.name}>
                <Text size="xs" fw={450}>
                  {coinId}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.cellContent}>
          <Title size={15} fw={600}>
            {balance}
          </Title>
        </div>
      </td>
      <td>
        <div className={styles.cellContent}>
          <Title size={15} fw={600}>
            {price}
          </Title>
        </div>
      </td>
      <td>
        <div className={styles.cellContent}>
          <Title size={15} fw={600}>
            {rate}
          </Title>
        </div>
      </td>
      <td>
        <div className={styles.cellContent}>
          <div className={styles.options}>
            <div
              className={clsx(
                styles.colorProcent,
                changePercent > -1 && styles.colorGreen
              )}
            >
              <Title size={15} fw={400}>
                {changePercent}%
              </Title>
            </div>
            <div className={styles.option}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

