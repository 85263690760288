import { useEffect, useState } from 'react';
import { Block, Button } from 'components';
import { Text } from '@mantine/core';
import { useGetRecentAuthDevicesQuery } from 'lib/profile/useChangeProfileQuery';
import { Sessions } from './components';

import styles from '../../Security.module.scss';

export const ListDevices: React.FC = () => {
    const { data: authDevices } = useGetRecentAuthDevicesQuery();
    const [visibleCount, setVisibleCount] = useState<number>(3);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const handleToggleView = () => {
        if (isExpanded) {
            setVisibleCount(3);
        } else {
            setVisibleCount(Math.min(10, authDevices?.data?.length || 0));
        }
        setIsExpanded(!isExpanded);
    };
    return <>
        {
            authDevices?.data && authDevices?.data.length > 0 ? (
                <Block color="dark">
                    <div className={styles.wrapper}>
                        <div className={styles.settings}>
                                <Text size="lg" fw="bold">
                                    Активные сессии
                                </Text>
                        </div>
                    </div>
                    {authDevices.data.slice(0, visibleCount).map((device) => (
                        <div key={device._id}>
                            <Sessions device={device} />
                        </div>
                    ))}
                        <div className={styles.all}>
                            <Button type="button" onClick={handleToggleView}>
                                {isExpanded ? 'Свернуть' : 'Показать еще'}
                            </Button>
                        </div>
                </Block>
            ) : <></>
        }
    </>
}
