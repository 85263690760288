

import { Button, Input } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import styles from './FormSupportRestore.module.scss'
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';
import { useSendEmailForPasswordRecoveryQuery } from 'lib';
import { SendEmailRecoveryPasswordT } from 'services';

export const FormSupportRestore: React.FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<SendEmailRecoveryPasswordT>();
    const navigate = useNavigate();

    const {
        mutate: sendEmail,
        isSuccess,
        isLoading,
    } = useSendEmailForPasswordRecoveryQuery();

    const onSubmit: SubmitHandler<SendEmailRecoveryPasswordT> = ({ email }) => {
        sendEmail({ email });
    };

    const next = () => {
        navigate(RouteNames.LOGIN);
    }

    return (
        <>
            {isSuccess && (
                <div className={styles.successMessage}>
                    <div className={styles.header}>
                        <h4>Перейдите по ссылке в письме</h4>
                    </div>
                    <div className={styles.wrapper}>
                        <Button type="button" onClick={next} fullWidth >Перейти к авторизации</Button>
                    </div>
                </div>
            )}
            {!isSuccess && (
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: "Заполните поле" }}
                        defaultValue=""
                        render={({ field }) => (
                            <Input
                                maxLength={100}
                                label="Почта"
                                placeholder="Укажите почту"
                                type="email"
                                fullWidth
                                autoFocus
                                {...field}
                                errorMessage={errors.email?.message}
                                error={!!errors.email}
                            />
                        )}
                    />
                    <div className={styles.buttons}>
                        <Button
                            type="submit"
                            fullWidth
                            className={styles.next}
                            isLoading={isLoading}
                        >
                            Продолжить
                        </Button>
                        <Button
                            type="button"
                            color="grey"
                            fullWidth
                            onClick={next}
                        >
                            Назад
                        </Button>
                    </div>
                </form>
            )}
        </>
    );
};
