import { Input as Field } from '@mantine/core';
import React from 'react';
import styles from './Input.module.scss';
import clsx from 'clsx';
import { Text } from '@mantine/core'

type Props = {
    placeholder: string;
    label?: string;
    required?: boolean;
    type?: "password" | "text" | "email" | "number";
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Add onFocus prop
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;   // Add onBlur prop
    autoFocus?: boolean;
    fullWidth?: boolean;
    icon?: React.ReactNode;
    errorMessage?: string;
    error?: boolean;
    autoComplete?: "on" | "off";
    maxLength?: number;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
    ({
        label,
        placeholder,
        type = "text",
        required = false,
        value,
        onChange,
        onFocus,
        onBlur,
        autoFocus,
        fullWidth,
        icon,
        errorMessage,
        error,
        autoComplete = "off",
        maxLength = 1000
    }, ref) => {

        const errorStyles = clsx({ [styles.error]: error || !!errorMessage })
        const errorWrapperStyles = clsx({ [styles.errorInputWrapper]: error || !!errorMessage })
        const inputStyles = clsx(styles.input, icon && styles.leftIcon, fullWidth && styles.fullWidth, errorStyles);
        const sectionStyles = clsx(icon && styles.icon, errorStyles);
        const wrapperStyles = { label: styles.label, root: clsx(styles.inputWrapper, errorWrapperStyles) }

        return (
            <>
                <Field.Wrapper
                    classNames={wrapperStyles}
                    label={label} description="" error="" withAsterisk={required}>
                    <Field
                        leftSection={icon}
                        autoFocus={autoFocus}
                        type={type}
                        classNames={{
                            input: inputStyles,
                            section: sectionStyles
                        }}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoComplete={autoComplete}
                    />
                    {!!errorMessage && <p className={styles.errorMessage}>
                        <Text size={'sm'}>{errorMessage}</Text>
                    </p>}
                </Field.Wrapper>
            </>
        );
    }
);

Input.displayName = 'Input';
