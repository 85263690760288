import { NavLink, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import { IoLogOutOutline } from "react-icons/io5";
import { RouteNames } from 'navigate/routeNames';
import { toggleAuthState } from 'store/authStateSlice';
import { useAppDispatch } from 'lib/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { removeToken } from 'services';
import { useAppSelector } from 'lib/useAppSelect';
import { ROUTERS_PROFILE, ROUTERS_WALLET } from 'lib/consts/routes';
import { setUserInfo } from 'store/userStateSlice';


export const Header: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isMobile } = useAppSelector((state) => state.isMobile);
    const logInOut = () => {
        dispatch(toggleAuthState({ isLogin: false }));
        navigate(RouteNames.PROFILE);
        removeToken();
        dispatch(setUserInfo({ user: undefined }));
    }

    const location = useLocation();
    const currentPath = location.pathname;

    // Функция для получения первой части пути
    const getFirstPathSegment = (path: string) => {
        const segments = path.split('/').filter(Boolean);
        return segments.length > 0 ? `/${segments[0]}` : '/';
    };

    const routers = {
        [RouteNames.PROFILE]: ROUTERS_PROFILE,
        [RouteNames.WALLET]: ROUTERS_WALLET
    }[getFirstPathSegment(currentPath)]

    const navigates = routers?.length ? routers : undefined

    return (
        <div className={styles.header}>
            <div className={styles.menu}>
                {navigates && navigates.map((item) => {
                    return (
                        <NavLink
                            to={item.path}
                            className={() =>
                                currentPath === item.path ? `${styles.link} ${styles.active} ` : styles.link
                            }
                        >
                            <h4>{item.name}</h4>
                        </NavLink>
                    )
                })}
            </div>
            <div onClick={logInOut} className={styles.logout}>
                <IoLogOutOutline size={18} /> <h4>Выйти</h4>
            </div>
        </div>
    )
}