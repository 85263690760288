
import { Button, Checkbox, Input, InputPassword, Link, TelegramSVG } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useFastRegistrationQuery, useNewRegistrationQuery } from 'lib/auth/useAuthorizationQuery';
import { PiWarningCircleLight } from "react-icons/pi"
import { CiUser } from "react-icons/ci";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillHome } from "react-icons/ai";
import styles from './FormRegistration.module.scss'
import { useEffect, useState } from 'react';
import { Title } from '@mantine/core';
import { RouteNames } from 'navigate/routeNames';
import { TG_BOT_URL } from 'lib/consts/links';

interface RegistrationFormInputs {
    email: string;
    password: string;
}

export const FormRegistration: React.FC = () => {

    const {
        mutate: sendSignupForm,
        isLoading: isLoadingSendSignupForm
    } = useNewRegistrationQuery();

    const {
        mutate: fastRegistration,
        isLoading: isLoadingFastRegistration,
    } = useFastRegistrationQuery();

    const { control, handleSubmit, watch, formState: { errors } } = useForm<RegistrationFormInputs>();

    const email = watch("email", "");
    const [checkedUserEvent, setCheckedUserEvent] = useState(false);
    const [checkedUSerDate, setCheckedUSerDate] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (!checkedUserEvent || !checkedUSerDate) return setDisabled(true);

        if (isLoadingFastRegistration || isLoadingSendSignupForm) return setDisabled(true);

        setDisabled(false)
    }, [checkedUserEvent, checkedUSerDate, isLoadingSendSignupForm, isLoadingFastRegistration])

    const onSubmit: SubmitHandler<RegistrationFormInputs> = (data) => {
        if (!data.email || !data.password) return;
        sendSignupForm({ email: data.email, password: data.password });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Title size={40}>Создать аккаунт</Title>
                </div>
                <div className={styles.enter}>
                    <h4>Уже есть аккаунт?</h4>&nbsp;
                    <Link to={RouteNames.LOGIN}>
                        <h4>Войти</h4>
                    </Link>
                </div>
                <div className={styles.notif}>
                    <div className={styles.warn}>
                        <PiWarningCircleLight size={17} />
                    </div>
                    <div className={styles.block}>
                        <h6>Проект по обновлению находится в процессе, и мы постепенно&nbsp;включаем новые модули и компоненты. Информация о текущем статусе будет актуализироваться по мере завершения работы.</h6>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form} autoComplete="off">
                    <div className={styles.inputs}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Заполните поле" }}
                            render={({ field }) => (
                                <Input
                                    icon={<CiUser />}
                                    placeholder="Почта"
                                    type={"email"}
                                    fullWidth
                                    maxLength={200}
                                    autoFocus={!email}
                                    {...field}
                                    errorMessage={errors.email && errors.email.message}
                                    error={!!errors?.email}
                                    autoComplete='off'
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            rules={{
                                required: "Заполните поле",
                                minLength: {
                                    value: 8,
                                    message: "Пароль должен быть длиной не менее 8 символов"
                                }
                            }}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    placeholder="Пароль"
                                    {...field}
                                    errorMessage={errors.password && errors.password.message}
                                    error={!!errors?.password}
                                    autoComplete='off'
                                />
                            )}
                        />
                    </div>
                    <div className={styles.options}>
                        <Checkbox
                            onChange={(event) => setCheckedUserEvent(event.currentTarget.checked)}
                            checked={checkedUserEvent}
                            label={"Пользовательское соглашение"}
                        />
                        <Checkbox
                            onChange={(event) => setCheckedUSerDate(event.currentTarget.checked)}
                            checked={checkedUSerDate}
                            label={"Политика обработки персональных данных"}
                        />
                    </div>
                    <div className={styles.login}>
                        <Button type="submit" disabled={disabled} fullWidth isLoading={isLoadingSendSignupForm}>Создать аккаунт</Button>
                        <Button type="button" color='gradient' disabled={disabled} fullWidth onClick={fastRegistration} isLoading={isLoadingFastRegistration}>Быстрая регистрация</Button>
                    </div>
                </form>
                <div className={styles.message}>
                    <h5>
                        При создании аккаунта ты соглашаешься с&nbsp;<Link to="">условиями использования</Link>
                    </h5>
                </div>
                <div className={styles.tech}>
                    <Link
                        to={TG_BOT_URL}
                        type="empty"
                        target="_blank"
                    >
                        <div className={styles.linkTech}>
                            <div className={styles.svgTelegramm}>
                                <TelegramSVG />
                            </div>
                            <h5>
                                Техническая поддержка
                            </h5>
                        </div>
                    </Link>
                    <div className={styles.home}>
                        <Link
                            to={RouteNames.START}
                            type="empty"
                        >
                            <AiFillHome size={20} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}