import { FormNewPassword } from './components/FormNewPassword';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from "navigate/routeNames";
import toast from 'react-hot-toast';

import styles from './PasswordRecovery.module.scss'

type Props = {
    email?: string | null;
    token?: string | null;
}

export const PasswordRecovery: React.FC<Props> = ({ email, token }) => {
    const navigate = useNavigate();
    // password-recovery?email=scarletkorry@tohru.org&token=cc84a9a8-9332-4659-aeed-d5f94a1354a2
    useEffect(() => {
        if (!email || !token) return
    }, [])

    useEffect(() => {
        if (!email || !token) {
            toast.error('Что-пошло не так', {
                id: 'unique-error',
                duration: 3000
            });
            navigate(RouteNames.LOGIN);
            return
        }
        if (email && token) {
            console.log(email, token)
        }
    }, [email, token]);

    return (
        <>
            <div className={styles.wrapper}>
                <FormNewPassword email={email} token={token} />
            </div>
        </>
    )
}