import { Block, Button, CheckedSVG, CopySVG, Modal } from "components";
import { useAppSelector } from "lib/useAppSelect";
import { CheckIcon, Text, Title } from "@mantine/core";

import { setFastRegistrationDate } from "store/fastEnterStateSlice";
import { useAppDispatch } from "lib/useAppDispatch";
import { RouteNames } from "navigate/routeNames";
import { useNavigate } from "react-router-dom";

import styles from "./ModalViewInfoEmail.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { NBSP } from "consts/consts";

type Props = {
    opened: boolean;
    close: () => void;
};

export const ModalViewInfoEmail: React.FC<Props> = ({ opened, close }) => {
    const { userFastDate } = useAppSelector((state) => state.userFastDate);
    const [copy, setCopy] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onClose = () => {
        dispatch(setFastRegistrationDate({ userFastDate: undefined }));
        close();
    };

    const next = () => {
        onClose();
        navigate(RouteNames.PROFILE + RouteNames.SECURITY);
    };

    const email = userFastDate?.user_info?.email || "";
    const password = userFastDate?.user_info?.password || "";


    const handleCopy = () => {
        navigator?.clipboard.writeText(`Почта: ${email}  Пароль: ${password}`);
        setCopy(true)
    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            setCopy(false);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [copy]);

    console.log(copy)
    return (
        <Modal opened={opened} close={onClose}>
            <Block>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <Title size={18}>
                            Скопируйте временные почту и пароль <br />
                            для входа, чтобы не потерять их
                        </Title>
                    </div>
                    <div className={styles.text}>
                        <h5>Добро пожаловать!{NBSP}🎉
                            За{NBSP}регистрацию{NBSP}вы{NBSP}
                            получаете подарок — 1000 Mirracoin! Пожалуйста,
                            не забудьте обновить ваш адрес электронной почты.
                        </h5>
                    </div>
                    <div>
                        <div className={styles.row}>
                            <Title size={15}>Почта:</Title>
                            <Text size={"md"}>{email}</Text>
                            {
                                copy ?
                                    <div className={clsx(styles.copy, styles.check)}>
                                        <CheckIcon width={15}/>
                                    </div> 
                                    :
                                    <div className={styles.copy} onClick={handleCopy}>
                                        <CopySVG />
                                    </div>
                            }
                        </div>
                        <div className={styles.row}>
                            <Title size={15}>Пароль:</Title>
                            <Text size={"md"}>{password}</Text>
                        </div>
                    </div>
                    <Button className={styles.submit} onClick={next} fullWidth>
                        Изменить почту и пароль
                    </Button>
                    <div className={styles.next}>
                        <h5>
                            Пожалуйста, нажмите на кнопку, чтобы изменить временный пароль и адрес электронной почты
                        </h5>
                    </div>
                </div>
            </Block>
        </Modal>
    );
};
